import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Tabs, Tab } from 'react-bootstrap';
import Select from 'react-select';
import Header from "../Header/index";
import Sidebar from "../sidebar";
import CalendarComponent from '../Calendar';
import uri from '../../config';
import Navigation from '../navbar2';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

function ProjectDetails() {
  const [key, setKey] = useState('task');
  const [organization, setOrganization] = useState('');
  const [projectName, setProjectName] = useState('');
  const [tasks, setTasks] = useState([]);
  const [selectedTasks, setSelectedTasks] = useState([]);
  const [addedTasks, setAddedTasks] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [addedUsers, setAddedUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [searchFilter, setSearchFilter] = useState('');
  const [options, setOptions] = useState([]);
  const navigate = useNavigate(); // Initialize useNavigate
  const project = localStorage.getItem('selectedProjectName')

  useEffect(() => {
    const storedOrganization = localStorage.getItem('organization');
    const storedProjectName = localStorage.getItem('selectedProjectName');
    if (storedOrganization) setOrganization(storedOrganization);
    if (storedProjectName) setProjectName(storedProjectName);

    fetchTasks();
    fetchSelectedTasks();
    fetchUsersByProject();
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);  // Show loading indicator
      try {
        // Perform the API request with organization as a query parameter
        const response = await axios.get(`${uri}/api/findusers`, {
          params: {
            organization: organization  // Send organization as query param
          }
        });

        // Map response data to options for a select component
        const userOptions = response.data.map(user => ({
          value: user._id,
          label: `${user.firstName} ${user.lastName}`
        }));

        // Update the options state
        setOptions(userOptions);
      } catch (error) {
        console.error('Error fetching users:', error);
      } finally {
        setLoading(false);  // Hide loading indicator
      }
    };

    // Fetch users only if organization is available
    if (organization) {
      fetchUsers();
    }
  }, [organization]); // Dependency on the organization value
  // Dependency on organization

  useEffect(() => {
    filterUsers();
  }, [searchFilter, users]);

  const fetchTasks = async () => {
    try {
      const organization = localStorage.getItem('organization');
      setLoading(true);
      const response = await axios.get(`${uri}/api/task`, { params: { organization } });
      // console.log("Fetched Tasks: ", response.data); // Log API response
      setTasks(response.data);
    } catch (error) {
      console.error('Error fetching tasks:', error);
      setError('Failed to fetch tasks.');
    } finally {
      setLoading(false);
    }
  };

  const fetchSelectedTasks = async () => {
    const projectName = localStorage.getItem('selectedProjectName');
    try {
      setLoading(true);
      const response = await axios.get(`${uri}/api/addtask/${projectName}`);

      // Ensure taskDesc is mapped correctly
      setAddedTasks(response.data.map(task => ({
        value: task.taskName,
        label: task.taskName,  // taskName only
        desc: task.taskDesc    // Task description
      })));
    } catch (error) {
      console.error('Error fetching selected tasks:', error);
      setError('Failed to fetch selected tasks.');
    } finally {
      setLoading(false);
    }
  };

  const fetchUsersByProject = async () => {
    const selectedProjectName = localStorage.getItem('selectedProjectName');
    try {
      setLoading(true);
      const response = await axios.get(`${uri}/api/usersByProject`, { params: { projectName: selectedProjectName } });
      setUsers(response.data);
      setAddedUsers(response.data);
    } catch (error) {
      console.error('Error fetching users by project:', error);
      setError('Failed to fetch users.');
    } finally {
      setLoading(false);
    }
  };

  const handleTaskChange = (selectedOptions) => {
    const updatedTasks = selectedOptions.map(option => ({
      taskName: option.value,  // taskName
      taskDesc: option.desc  // Directly use taskDesc from the selected option
    }));

    setSelectedTasks(updatedTasks);  // Update selected tasks
  };

  const clearErrorAfterTimeout = () => {
    setTimeout(() => setError(''), 3000);
  };

  const addSelectedTasks = async () => {
    const taskDetails = selectedTasks.map(task => ({
      taskName: task.taskName,
      taskDesc: task.taskDesc
    }));

    try {
      setLoading(true);
      const organization = localStorage.getItem('organization'); // Fetch organization from local storage

      // Fetch existing tasks for the project
      const existingTasksResponse = await axios.get(`${uri}/api/addtask/${projectName}`);
      const existingTasks = existingTasksResponse.data.map(task => task.taskName);

      // Filter out tasks that are already added
      const newTasks = taskDetails.filter(task => !existingTasks.includes(task.taskName));

      if (newTasks.length === 0) {
        setError('Please select a task to add');
        clearErrorAfterTimeout(); // Automatically dismiss error after 5 seconds
        return;
      }

      // Add new tasks to the project with organization
      await axios.post(`${uri}/api/addtask`, {
        projectName,
        tasks: newTasks,  // Send both taskName and taskDesc
        organization      // Include organization in the payload
      });

      fetchSelectedTasks(); // Refresh the selected tasks table
      setSelectedTasks([]); // Clear selected tasks
    } catch (error) {
      console.error('Error adding tasks:', error);
      setError('Failed to add tasks.');
    } finally {
      setLoading(false);
    }
  };




  const handleUserSelection = (selectedOptions) => {
    setSelectedUsers(selectedOptions);
  };

  const addUsersToProject = async () => {
    if (selectedUsers.length === 0) {
      setError('Please select at least one user to add');
      clearErrorAfterTimeout(); // Automatically dismiss error after 5 seconds
      return;
    }

    const selectedUserIds = selectedUsers.map(option => option.value);
    const selectedUserNames = selectedUsers.map(option => `${option.label}`);

    try {
      await axios.post(`${uri}/api/addToProject`, {
        projectName,
        userIds: selectedUserIds
      });

      const userName1 = localStorage.getItem('userName');
      const notifications = selectedUserIds.map((userId, index) => ({
        userName: selectedUserNames[index],
        approverName: userName1,
        message: `${userName1} has added you to the project: ${projectName}.`,
        organization,
      }));

      await Promise.all(notifications.map(notification =>
        axios.post(`${uri}/api/notifications`, notification)
      ));

      setAddedUsers((prevAddedUsers) => {
        const updatedUsers = [...prevAddedUsers];
        selectedUserIds.forEach(userId => {
          const userToAdd = users.find(user => user._id === userId);
          if (userToAdd) {
            updatedUsers.push(userToAdd);
          }
        });
        return updatedUsers;
      });

      fetchUsersByProject();
      toast.success('Users added to the project successfully!');
    } catch (error) {
      console.error('Error adding users to project:', error);
      setError('Failed to add users to project.');
      clearErrorAfterTimeout();
    }
  };



  const filterUsers = () => {
    let filtered = users.filter(user => {
      const fullName = `${user.firstName} ${user.lastName}`.toLowerCase();
      return fullName.includes(searchFilter.toLowerCase());
    });
    return filtered;
  };

  const handleSearchInputChange = (e) => {
    setSearchFilter(e.target.value);
  };

  const handleBackClick = () => {
    navigate('/OrgSetup?key=Projects'); // Navigate to OrgSetup with query parameter
  };

  const renderTaskDropdown = () => {
    // Filter out tasks that are already added to the project
    const availableTasks = tasks.filter(
      task => !addedTasks.some(addedTask => addedTask.value === task.taskName)
    );

    // Map the filtered tasks into options
    const options = availableTasks.map(task => ({
      value: task.taskName,
      label: task.taskName,  // Display taskName as label
      desc: task.taskDesc  // Keep taskDesc separately
    }));

    const selectedValue = selectedTasks.map(task => ({
      value: task.taskName,
      label: task.taskName,  // Show taskName only
      desc: task.taskDesc
    }));

    return (
      <div className="mb-3">
        <label>Select Task:</label>
        <div className="dropdown-container">
          <Select
            isMulti
            options={options}  // Use filtered task options
            value={selectedValue}
            onChange={handleTaskChange}
            className="basic-multi-select"
            classNamePrefix="select"
            styles={{ container: base => ({ ...base, width: '50%' }) }}
          />
          <button className="btn btn-outline-primary" onClick={addSelectedTasks} disabled={loading}>
            {loading ? 'Adding...' : 'Add Task'}
          </button>
        </div>
      </div>
    );
  };




  const removeTask = async (taskName) => {
    try {
      setLoading(true);
      await axios.delete(`${uri}/api/addtask/removeTask`, {
        data: {
          projectName,
          taskName,
          organization
        }
      });
      fetchSelectedTasks(); // Refresh the task list after removal
      toast.success('Task removed successfully!');
    } catch (error) {
      console.error('Error removing task:', error);
      setError('Failed to remove task.');
      toast.error('Failed to remove task.');
    } finally {
      setLoading(false);
    }
  };

  const renderAddedTasksTable = () => (
    <div className="table-responsive">
      <table className="table mt-3" style={{ width: '50%' }}>
        <thead>
          <tr>
            <th>Task Name</th>
            <th>Description</th>
            <th>Action</th> {/* New column for action */}
          </tr>
        </thead>
        <tbody>
          {addedTasks.map(task => (
            <tr key={task.value}>
              <td>{task.value}</td>
              <td>{task.desc || 'No description'}</td>
              <td>
                <button
                  className="btn btn-outline-danger"
                  onClick={() => removeTask(task.value)}
                  disabled={loading}
                >
                  {loading ? 'Removing...' : <FontAwesomeIcon icon={faTrash} />}
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );







  const renderUserDropdown = () => {
    // Filter users to exclude those already added
    const availableUsers = options.filter(
      option => !addedUsers.some(addedUser => addedUser._id === option.value)
    );

    return (
      <div className="mb-3">
        <label>Select Users:</label>
        <div className="dropdown-container">
          <Select
            isMulti
            options={availableUsers} // Use filtered options
            value={selectedUsers}
            onChange={handleUserSelection}
            className="basic-multi-select"
            classNamePrefix="select"
            styles={{ container: base => ({ ...base, width: '30%' }) }}
          />
          <button className="btn btn-outline-primary" onClick={addUsersToProject} disabled={loading}>
            {loading ? 'Adding...' : 'Add Users'}
          </button>
        </div>
      </div>
    );
  };


  const removeUserAccess = async (userId) => {
    const projectName = localStorage.getItem('selectedProjectName');
    const userName = localStorage.getItem('userName');

    try {
      setLoading(true);
      await axios.delete(`${uri}/api/addToProject/removeuser`, {
        data: {
          userId,
          projectName
        }
      });

      // Send notification to the user being removed
      await axios.post(`${uri}/api/notifications`, {
        userName: userName,
        message: `${userName} has removed you from the project: ${projectName}.`,
        organization,
        approverName: userName, // Assuming userName is the approver for notification purposes
      });

      // Refresh the users list after removal
      fetchUsersByProject();
      toast.success('User access removed successfully!');
    } catch (error) {
      console.error('Error removing user access:', error);
      setError('Failed to remove user access.');
      toast.error('Failed to remove user access.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="row2">
      <div className="col-md-2">
        <Header />
        <ToastContainer />
      </div>
      <div style={{ display: "flex", width: "auto", height: "100vh" }}>
        <Sidebar />

        <div className="container-fluid project-details" style={{ width: '88vw', marginTop: "7vw", marginLeft: '17vw' }}>
          <h4>{project}</h4>

          <button
            className="btn btn-outline-secondary mb-3"
            onClick={handleBackClick}
          >
            Back
          </button>


          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => {
              setKey(k);
              setError(''); // Clear error when tab changes
            }}
            className="mb-3"
          >

            <Tab eventKey="task" title="Task">
              <div className="tab-content border p-3">
                {error && <div className="alert alert-danger" role="alert">{error}</div>}
                {renderTaskDropdown()}
                {renderAddedTasksTable()}
              </div>
            </Tab>
            <Tab eventKey="access" title="Access">
              <div className="tab-content border p-3">
                {error && <div className="alert alert-danger" role="alert">{error}</div>}

                {renderUserDropdown()}
                <table className="table mt-3" style={{ width: '50%' }}>
                  <thead>
                    <tr>
                      <th>First Name</th>
                      <th>Last Name</th>
                      <th>Role</th>
                      <th>Action</th> {/* Add Action column for remove button */}
                    </tr>
                  </thead>
                  <tbody>
                    {filterUsers().map(user => (
                      <tr key={user._id}>
                        <td>{user.firstName}</td>
                        <td>{user.lastName}</td>
                        <td>{user.role}</td>
                        <td>
                          <button
                            className="btn btn-outline-danger"
                            onClick={() => removeUserAccess(user._id)}
                            disabled={loading}
                          >
                            {loading ? 'Removing...' : <FontAwesomeIcon icon={faTrash} />}
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </div>

  );
}

export default ProjectDetails;
