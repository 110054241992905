import React, { useState, useEffect } from 'react';
import { Container, Tab, Tabs, Form, Button, Table, FormControl, Toast, ToastContainer } from 'react-bootstrap';
import './orgsetup.css';
import axios from "axios";
import { BsPencil, BsTrash, BsCheck, BsX } from 'react-icons/bs';
import uri from '../../config';
import Sidebar from '../sidebar';
import Header from "../Header/index";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import DepartmentPage from '../Department';
import OkraPage from "../Okra/Index";
import ProjectTable from "../projectPage";
import { useLocation } from 'react-router-dom';
import Team from "../Team";
import { evaluate } from "mathjs";


const OrgSetup = () => {
  const [key, setKey] = useState('Department');
  const location = useLocation(); // Get current location
  const [objectives, setObjectives] = useState([]);
  const [keyresults, setKeyResults] = useState([]);
  const [measurements, setMeasurements] = useState([]);
  const [metrics, setMetrics] = useState([]);
  const [newClient, setNewClient] = useState({ name: '', clientemail: '', address: '', note: '', currency: '' });
  const organization = localStorage.getItem('organization');
  const [projectclients, setProjectClients] = useState([]);
  const [additionalFields, setAdditionalFields] = useState([]);
  const [additionalFieldsOptions, setAdditionalFieldsOptions] = useState([]);
  const [departments, setDepartments] = useState([])
  const [toastVariant, setToastVariant] = useState(""); // Success by default
  const [metricOptions, setMetricOptions] = useState([]);
  const [departmentOptions, setDepartmentOptions] = useState([]);
  const [formula, setFormula] = useState('');

  const [formData, setFormData] = useState({
    objectiveName: '',
    objectiveType: '',
    objectiveDescription: '',
    cadence: '',
    keyResultName: '',
    keyResultDescription: '',
    fixedDuration: false,
    targetDate: '',
    objective: '',
    metrics: [],
    metricsName: '',
    metricsUnit: '',
    formula: '',
    measurements: [],
    measurementName: '',
    measurementDesc: '',
    measurementUnit: '',
    source: '',
    frequency: '',
    taskName: '',
    taskDesc: '',
    taskKeyResult: '',
    taskMetrics: '',
    taskMeasurement: [],
    additionalFieldName: '',
    description: '',
    isRequired: false,
    additionalFields: [],
    departments: [],
  });
  const [data, setData] = useState({
    objectives: [],
    keyResults: [],
    metrics: [],
    measurements: [],
    task: [],
    client: [],
    additionalfield: []

  });

  const initialFormData = {
    objectiveName: '',
    objectiveType: '',
    objectiveDescription: '',
    cadence: '',
    keyResultName: '',
    keyResultDescription: '',
    fixedDuration: false,
    targetDate: '',
    objective: '',
    metrics: [],
    measurements: [],
    metricsName: '',
    metricsUnit: '',
    formula: '',
    measurementName: '',
    measurementDesc: '',
    measurementUnit: '',
    source: '',
    frequency: '',
    taskName: '',
    taskDesc: '',
    taskKeyResult: '',
    taskMetrics: '',
    taskMeasurement: [],
    additionalFieldName: '',
    description: '',
    isRequired: false,
    additionalFields: [],
    departments: [],
  };

  const [sampleCalculation, setSampleCalculation] = useState({
    formula: "",
    result: "",
    error: "",
  });

  const [measurementsValues, setMeasurementsValues] = useState({});

  const [loading, setLoading] = useState(false);
  const [editMode, setEditMode] = useState({});
  const [editData, setEditData] = useState({});
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("success");
  const [progress, setProgress] = useState(0);
  const [metricsEditMode, setMetricsEditMode] = useState(null); // For Metrics tab
  const fetchData = async () => {
    try {
      const response = await axios.post(`${uri}/api/data`, { organization: organization });
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    // Clear departments when the tab changes
    setFormData(prevData => ({
      ...prevData,
      departments: [], // Clear departments selection
    }));
  }, [key]);

  useEffect(() => {
    const sanitizeKey = (key) => key.replace(/[^a-zA-Z0-9_]/g, "_");

    const selectedMeasurements = metricsEditMode
      ? editData.measurements
      : formData.measurements;

    // console.log("Selected Measurements:", selectedMeasurements);

    // Map measurement names to sanitized keys and assign dummy values
    const exampleValues = selectedMeasurements.reduce((acc, measurement) => {
      const sanitizedKey = sanitizeKey(measurement.label);
      acc[sanitizedKey] = Math.floor(Math.random() * 100) + 1; // Assign random values
      return acc;
    }, {});

    // console.log("Mapped Example Values:", exampleValues);

    setMeasurementsValues(exampleValues);

    try {
      const originalFormula = metricsEditMode
        ? editData.formula
        : formData.formula || "";

      // Replace measurement names with sanitized keys
      let formulaToEvaluate = originalFormula;
      selectedMeasurements.forEach((measurement) => {
        const originalName = measurement.label; // Original name
        const sanitizedKey = sanitizeKey(originalName); // Sanitized key
        const regex = new RegExp(`\\b${originalName}\\b`, "g"); // Exact match
        formulaToEvaluate = formulaToEvaluate.replace(regex, sanitizedKey);
      });

      // console.log("Sanitized Formula Before Replacement:", formulaToEvaluate);

      // Replace sanitized keys with their numeric values
      Object.keys(exampleValues).forEach((key) => {
        const regex = new RegExp(`\\b${key}\\b`, "g"); // Match variable name
        formulaToEvaluate = formulaToEvaluate.replace(regex, exampleValues[key]);
      });

      // console.log("Formula After Replacing Values:", formulaToEvaluate);

      // Process the formula further (e.g., user-provided inputs)
      const processedFormula = formulaToEvaluate.replace(
        /([+\-*/])(\d+)/g,
        (match, operator, number) => ` ${operator} ${number} `
      );

      // console.log("Processed Formula with User Inputs:", processedFormula);

      // Validate the formula
      const validFormulaRegex = /^[0-9+\-*/()._\s]*$/;
      if (!validFormulaRegex.test(processedFormula)) {
        throw new Error("Invalid characters in formula.");
      }

      // Re-sanitize the formula for safety
      const sanitizedFinalFormula = processedFormula.replace(
        /[^0-9+\-*/()._\s]/g,
        ""
      );

      // console.log("Sanitized Final Formula:", sanitizedFinalFormula);

      // Evaluate the formula
      let result = evaluate(sanitizedFinalFormula); // Ensure `evaluate` is defined or imported

      // Multiply the result by 100 if the unit is a percentage
      if (formData.metricsUnit === "percentage") {
        result *= 100;
      }

      // console.log("Result:", result);

      setSampleCalculation({
        formula: sanitizedFinalFormula,
        result,
        error: "",
      });
    } catch (error) {
      console.error("Calculation Error:", error);
      setSampleCalculation({
        formula: "",
        result: "",
        error: error.message || "Invalid formula or calculation error.",
      });
    }
  }, [
    formData.formula,
    editData.formula,
    formData.measurements,
    editData.measurements,
    metricsEditMode,
    formData.metricsUnit, // Add metricsUnit dependency
  ]);


  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const queryKey = queryParams.get('key');
    if (queryKey) {
      setKey(queryKey); // Update the key if query parameter exists
    }
  }, [location.search]);

  useEffect(() => {
    setFormData((prevData) => ({
      ...prevData,
      departments: [], // Clear departments selection
    }));
  }, [key]);

  useEffect(() => {
    fetchData(); // Fetch data on component mount
    fetchAdditionalFields();
    fetchDepartments();
    fetchClients(); // Fetch clients when component mounts
  }, []);

  // Update the handleChange function to handle department changes
  const handleDepartmentChangeEdit = (selectedOptions) => {
    const selectedDepartments = selectedOptions.map(option => option.label); // Extract labels only
    setEditData(prevState => ({
      ...prevState,
      departments: selectedDepartments, // Store only labels
    }));
  };
  const startToastTimer = (variant, message) => {
    setProgress(0);
    setToastType(variant);
    setToastMessage(message);
    setShowToast(true);

    const duration = 3000; // Total duration for toast (3 seconds)
    const interval = 30; // Update every 30ms
    let elapsed = 0;

    const timer = setInterval(() => {
      elapsed += interval;
      setProgress((elapsed / duration) * 100); // Update progress percentage
      if (elapsed >= duration) {
        clearInterval(timer);
        setShowToast(false); // Close toast
      }
    }, interval);
  };

  const fetchDepartments = async () => {
    try {
      const organization = localStorage.getItem('organization');
      const response = await axios.get(`${uri}/api/departments`, {
        params: { organization }
      });

      // Map the API response to the expected format for react-select
      setDepartments(
        response.data.map(dept => ({
          value: dept._id,   // Assuming _id as the unique identifier
          label: dept.name   // Assuming name is the department's display name
        }))
      );
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };




  const handleFieldDoubleClick = (id, field, value) => {
    setEditMode({ [id]: true }); // Activate edit mode for the specific row based on its ID
    setEditData((prev) => ({
      ...prev,
      [field]: Array.isArray(value)
        ? value // If value is already an array, use it as is
        : value
          ? [value] // If value exists but is not an array, wrap it in an array
          : [], // If value is null or undefined, set it to an empty array
    }));
  };


  const fetchAdditionalFields = async () => {
    try {
      const response = await axios.get(`${uri}/api/additional-fields/${organization}`);
      setAdditionalFields(response.data);
      const fieldsOptions = response.data.map(field => ({
        value: field.additionalFieldName,
        label: field.additionalFieldName,
      }));
      setAdditionalFieldsOptions(fieldsOptions);
    } catch (error) {
      console.error('Error fetching additional fields:', error);
    }
  };

  const updateFormula = (text) => {
    setFormData((prevData) => ({
      ...prevData,
      formula: prevData.formula + text,
    }));
  };

  const handleMeasurementsChange = (selectedOptions) => {
    const selectedMeasurementNames = selectedOptions.map((option) => option.label);

    setFormData((prevData) => {
      let newFormula = prevData.formula;

      // Add newly selected measurements to the formula
      selectedMeasurementNames.forEach((measurementName) => {
        if (!newFormula.includes(measurementName)) {
          newFormula += newFormula ? ` ${measurementName}` : measurementName; // Append without duplicates
        }
      });

      // Remove deselected measurements and their adjacent operators
      const measurementsToRemove = (prevData.measurements || []).filter(
        (measurement) => !selectedMeasurementNames.includes(measurement.label)
      );
      measurementsToRemove.forEach((measurement) => {
        const regex = new RegExp(`(\\s?[+\\-*/]\\s?)?\\b${measurement.label}\\b(\\s?[+\\-*/]\\s?)?`, 'g'); // Match the measurement and surrounding operators
        newFormula = newFormula.replace(regex, ' ').trim();
      });

      // Clean up extra spaces and update state
      return {
        ...prevData,
        measurements: selectedOptions,
        formula: newFormula.replace(/\s+/g, ' '), // Remove extra spaces
      };
    });
  };




  // Handle measurements change when editing an existing metric
  const handleMeasurementsChangeEdit = (selectedOptions) => {
    const selectedMeasurementNames = selectedOptions.map((option) => option.label);

    setEditData((prevData) => {
      let newFormula = prevData.formula;

      // Add newly selected measurements to the formula
      selectedMeasurementNames.forEach((measurementName) => {
        if (!newFormula.includes(measurementName)) {
          newFormula += newFormula ? ` ${measurementName}` : measurementName; // Append without duplicates
        }
      });

      // Remove deselected measurements and their adjacent operators
      const measurementsToRemove = (prevData.measurements || []).filter(
        (measurement) => !selectedMeasurementNames.includes(measurement.label)
      );
      measurementsToRemove.forEach((measurement) => {
        const regex = new RegExp(`(\\s?[+\\-*/]\\s?)?\\b${measurement.label}\\b(\\s?[+\\-*/]\\s?)?`, 'g'); // Match the measurement and surrounding operators
        newFormula = newFormula.replace(regex, ' ').trim();
      });

      // Clean up extra spaces and update state
      return {
        ...prevData,
        measurements: selectedOptions,
        formula: newFormula.replace(/\s+/g, ' '), // Remove extra spaces
      };
    });
  };






  // Handles typing and changes in the formula field
  const handleFormulaChange = (event) => {
    const newFormula = event.target.value;

    const setData = metricsEditMode ? setEditData : setFormData;

    // Prevent consecutive arithmetic operators (e.g., ++, --)
    if (/[\+\-\*\/]{2,}/.test(newFormula)) {
      event.preventDefault();
      return;
    }

    // Update formula
    setData((prevData) => ({
      ...prevData,
      formula: newFormula,
    }));

    // Extract all tokens from the formula
    const measurementNamesInFormula = newFormula
      .split(/[\s+\-*/()]+/) // Split based on operators and spaces
      .filter((name) => name.trim() !== ""); // Filter out empty strings

    const updatedMeasurements = measurements.filter((measurement) =>
      measurementNamesInFormula.includes(measurement.measurementName)
    );

    const orderedMeasurements = measurementNamesInFormula
      .map((name) =>
        updatedMeasurements.find((measurement) => measurement.measurementName === name)
      )
      .filter(Boolean); // Filter out unmatched measurements

    setData((prevData) => ({
      ...prevData,
      measurements: orderedMeasurements.map((measurement) => ({
        label: measurement.measurementName,
        value: measurement._id,
      })),
    }));

    // Recalculate the formula with updated data
    recalculateFormula(newFormula, updatedMeasurements);
  };

  const recalculateFormula = (formula, selectedMeasurements) => {
    const sanitizeKey = (key) => key.replace(/[^a-zA-Z0-9_]/g, "_");

    // Map measurement names to sanitized keys and dummy values
    const exampleValues = selectedMeasurements.reduce((acc, measurement) => {
      const sanitizedKey = sanitizeKey(measurement.measurementName);
      acc[sanitizedKey] = Math.floor(Math.random() * 100) + 1; // Assign random values
      return acc;
    }, {});

    try {
      let formulaToEvaluate = formula;

      // Replace measurement names with sanitized keys
      selectedMeasurements.forEach((measurement) => {
        const originalName = measurement.measurementName;
        const sanitizedKey = sanitizeKey(originalName);
        const regex = new RegExp(`\\b${originalName}\\b`, "g");
        formulaToEvaluate = formulaToEvaluate.replace(regex, sanitizedKey);
      });

      // Replace sanitized keys with their numeric values
      Object.keys(exampleValues).forEach((key) => {
        const regex = new RegExp(`\\b${key}\\b`, "g");
        formulaToEvaluate = formulaToEvaluate.replace(regex, exampleValues[key]);
      });

      // Validate formula
      const validFormulaRegex = /^[0-9+\-*/()._\s]*$/;
      if (!validFormulaRegex.test(formulaToEvaluate)) {
        throw new Error("Invalid characters in formula.");
      }

      // Evaluate the formula
      let result = evaluate(formulaToEvaluate);

      // Adjust for percentage unit
      if (formData.metricsUnit === "percentage") {
        result *= 100;
      }

      setSampleCalculation({
        formula: formulaToEvaluate,
        result,
        error: "",
      });
    } catch (error) {
      setSampleCalculation({
        formula: "",
        result: "",
        error: error.message || "Invalid formula or calculation error.",
      });
    }
  };




  // Handles backspace logic in the formula field
  const handleFormulaKeyDown = (event) => {
    if (event.key === "Backspace") {
      const { selectionStart, selectionEnd } = event.target;
      const formula = (metricsEditMode ? editData.formula : formData.formula) || ""; // Ensure formula is always a string

      if (selectionStart === selectionEnd) { // No text is selected
        const beforeCursor = formula.slice(0, selectionStart); // Text before the cursor
        const afterCursor = formula.slice(selectionEnd); // Text after the cursor

        // Step 1: Remove the operator if it is the last character before the cursor
        const operatorMatch = beforeCursor.match(/[+\-*/]$/);

        if (operatorMatch) {
          const newFormula = beforeCursor.slice(0, -1) + afterCursor; // Remove only the last operator
          if (metricsEditMode) {
            setEditData((prevData) => ({
              ...prevData,
              formula: newFormula.trim(),
            }));
          } else {
            setFormData((prevData) => ({
              ...prevData,
              formula: newFormula.trim(),
            }));
          }
          event.preventDefault(); // Prevent default backspace behavior
          return;
        }

        // Step 2: Remove the entire measurement before the last operator
        const measurementMatch = beforeCursor.match(/([a-zA-Z0-9' ]+)(?=\s*[+\-*/]?$)/); // Match measurement before operator
        if (measurementMatch) {
          const removedMeasurement = measurementMatch[0].trim(); // Get the measurement name
          const startIndex = measurementMatch.index; // Start of the measurement
          const newFormula = beforeCursor.slice(0, startIndex) + afterCursor; // Remove the measurement

          if (metricsEditMode) {
            setEditData((prevData) => {
              const updatedMeasurements = (prevData.measurements || []).filter(
                (measurement) => measurement.label !== removedMeasurement
              ); // Remove measurement from selected list
              return {
                ...prevData,
                measurements: updatedMeasurements,
                formula: newFormula.trim(),
              };
            });
          } else {
            setFormData((prevData) => {
              const updatedMeasurements = (prevData.measurements || []).filter(
                (measurement) => measurement.label !== removedMeasurement
              ); // Remove measurement from selected list
              return {
                ...prevData,
                measurements: updatedMeasurements,
                formula: newFormula.trim(),
              };
            });
          }

          event.preventDefault(); // Prevent default backspace behavior
        }
      }
    }
  };



  const handleOperationClick = (operation) => {
    const setData = metricsEditMode ? setEditData : setFormData;

    setData((prevData) => {
      let newFormula = prevData.formula;

      // Prevent adding consecutive arithmetic operators (e.g., ++, **, --)
      if (/[+\-*/]$/.test(newFormula) && /[+\-*/]/.test(operation)) {
        // Replace the last operator with the new one
        newFormula = newFormula.slice(0, -1) + operation;
      } else {
        // Only add the operator if there's content in the formula or if it's an opening bracket
        if (newFormula || operation === '(') {
          newFormula += ` ${operation} `;
        }
      }

      return {
        ...prevData,
        formula: newFormula.trim(),
      };
    });
  };




  // Handle bracket button clicks
  const handleBracketClick = (bracket) => {
    const setData = metricsEditMode ? setEditData : setFormData;
    setData((prevData) => ({
      ...prevData,
      formula: prevData.formula + bracket,  // Add bracket to the formula
    }));
  };

  const handleClearFormula = () => {
    setFormData(prevFormData => ({
      ...prevFormData,
      measurements: [], // Clear selected measurements
      formula: '' // Clear the formula
    }));
  };

  const handleKeyResultChange = (keyResultId) => {
    const selectedKeyResult = keyresults.find((kr) => kr._id === keyResultId || kr.keyResultName === keyResultId);

    if (selectedKeyResult) {
      const updatedMetrics = selectedKeyResult.metrics.map((metric) => ({
        value: metric,
        label: metric,
      }));

      const updatedDepartments = selectedKeyResult.departments.map((dept) => ({
        value: dept,
        label: dept,
      }));

      // Get measurements for the selected metrics
      const selectedMeasurements = metrics
        .filter((metric) => updatedMetrics.some((m) => m.value === metric.metricsName))
        .flatMap((metric) => metric.measurements);

      setMetricOptions(updatedMetrics);
      setDepartmentOptions(updatedDepartments);

      setFormData({
        ...formData,
        taskKeyResult: selectedKeyResult.keyResultName,
        taskMetrics: updatedMetrics.map((metric) => metric.value),
        taskMeasurement: [...new Set(selectedMeasurements)], // Remove duplicates
        departments: updatedDepartments,
      });
    } else {
      fetchMetrics();
      setDepartmentOptions([]);
      setFormData({
        ...formData,
        taskKeyResult: '',
        taskMetrics: [],
        taskMeasurement: [],
        departments: [],
      });
    }
  };


  const handleEdit = (metric) => {
    // Map the measurement names to the format expected by the dropdown
    const associatedMeasurements = metric.measurements.map((measurementName) => {
      const foundMeasurement = measurements.find((m) => m.measurementName === measurementName);
      return foundMeasurement
        ? { label: foundMeasurement.measurementName, value: foundMeasurement._id }
        : null;
    }).filter(Boolean); // Remove null values for unmatched measurements

    setEditData({
      _id: metric._id, // Include _id for editing
      metricsName: metric.metricsName,
      metricsUnit: metric.metricsUnit,
      formula: metric.formula,
      source: metric.source,
      frequency: metric.frequency,
      measurements: associatedMeasurements, // Pre-populate measurements
      departments: metric.departments.map((dep) => ({
        label: dep,
        value: dep,
      })), // Pre-populate departments if necessary
    });

    setMetricsEditMode(metric._id); // Store the metric's _id to identify the metric being edited
  };



  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditData(prev => ({ ...prev, [name]: value }));
  };

  const handleEditMeasurementsChange = (selectedOptions) => {
    setEditData(prev => ({
      ...prev,
      measurements: selectedOptions,
    }));
  };




  // Function to handle key result change while editing
  const handleEditKeyResultChange = (taskId, keyResultName) => {
    const selectedKeyResult = keyresults.find((kr) => kr.keyResultName === keyResultName);
  
    if (selectedKeyResult) {
      // Get metrics for the selected Key Result
      const updatedMetrics = selectedKeyResult.metrics.map((metric) => ({
        value: metric,
        label: metric,
      }));
  
      // Get measurements for the selected metrics
      const selectedMeasurements = metrics
        .filter((metric) => updatedMetrics.some((m) => m.value === metric.metricsName))
        .flatMap((metric) => metric.measurements);
  
      // Get departments for the selected Key Result
      const updatedDepartments = selectedKeyResult.departments.map((dept) => ({
        value: dept,
        label: dept,
      }));
  
      setEditData({
        ...editData,
        taskKeyResult: keyResultName, // Use the name, not ID
        taskMetrics: updatedMetrics.map((metric) => metric.value), // Only values, for saving
        taskMeasurement: [...new Set(selectedMeasurements)], // Remove duplicates
        departments: updatedDepartments.map((dept) => dept.value), // Only values for saving
      });
  
      // Update metric and department options for dropdowns
      setMetricOptions(updatedMetrics);
      setDepartmentOptions(updatedDepartments);
    } else {
      // Reset fields if no key result is selected
      setEditData({
        ...editData,
        taskKeyResult: '',
        taskMetrics: [],
        taskMeasurement: [],
        departments: [],
      });
  
      fetchMetrics();
      setDepartmentOptions([]);
    }
  };
   



  const handleClientChange = (e) => {
    const { name, value } = e.target;
    setNewClient((prevState) => ({
      ...prevState,
      [name]: value // This will dynamically set the property based on the input's name
    }));
  };


  const measurementOptions = measurements.map((measurement) => ({
    value: measurement.measurementName,
    label: measurement.measurementName,
  }));

  const handleInputChange = (e) => {
    const { name, value, checked, type } = e.target;

    // Handle checkbox type
    if (type === 'checkbox') {
      setEditData((prevData) => ({
        ...prevData,
        [name]: checked,  // Update with the checked value
      }));
    }
    // Handle date type specifically
    else if (name === 'targetDate') {
      setEditData((prevData) => ({
        ...prevData,
        [name]: value || null,  // Set to null if the date input is empty
      }));
    }
    // Handle other input types
    else {
      setEditData((prevData) => ({
        ...prevData,
        [name]: value,  // Update with the input value
      }));
    }
  };

  const handleSave = async (type, id) => {
    try {
      let requestData;

      if (type === "metrics") {
        // Only transform measurements and departments when editing metrics
        const cleanedEditData = {
          ...editData,
          measurements: Array.isArray(editData.measurements)
            ? editData.measurements.map((measurement) => measurement.label)
            : [],
          departments: Array.isArray(editData.departments) && editData.departments.length > 0
            ? editData.departments.map((department) => department.label).filter(Boolean)
            : [],
        };

        requestData = { type, formData: cleanedEditData };
      } else {
        // Use editData as-is for non-metric edits
        requestData = { type, formData: editData };
      }

      await axios.put(`${uri}/api/orgsetup/${id}`, requestData);

      // Reset edit mode and reload data
      setEditMode({});
      setMetricsEditMode(null);
      fetchData();
    } catch (error) {
      console.error("Error updating data:", error);
    }
  };



  const handleCancel = () => {
    setEditMode({});
    setMetricsEditMode(null);
    setEditData({});
  };

  useEffect(() => {
    if (key === 'KeyResults') {
      fetchObjectives();
      fetchMetrics();
    }
  }, [key]);

  useEffect(() => {
    if (key === 'Tasks') {
      fetchObjectives();
      fetchMetrics();
      fetchMeasurement();
      fetchKeyresults();
    }
  }, [key]);

  useEffect(() => {
    if (key === 'Metrics') {
      fetchMeasurement();
    }
  }, [key]);
  useEffect(() => {
    if (key === 'Objective', 'KeyResults', 'Measurement', 'Metrics', 'Tasks', 'OKRA') {
      fetchDepartments();
    }
  }, [key]);

  const handleChange = (e) => {
    const { name, value, type, multiple, options, checked } = e.target;

    if (multiple) {
      const values = Array.from(options)
        .filter((option) => option.selected)
        .map((option) => option.value);

      if (metricsEditMode) {
        setEditData((prevState) => ({
          ...prevState,
          [name]: values,
        }));
      } else {
        setFormData((prevState) => ({
          ...prevState,
          [name]: values,
        }));
      }
    } else if (type === "checkbox") {
      if (metricsEditMode) {
        setEditData((prevState) => ({
          ...prevState,
          [name]: checked,
        }));
      } else {
        setFormData((prevState) => ({
          ...prevState,
          [name]: checked,
        }));
      }
    } else {
      if (metricsEditMode) {
        setEditData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      } else {
        setFormData((prevState) => ({
          ...prevState,
          [name]: value,
        }));
      }
    }
  };


  const handleDepartmentChange = (selectedDepartments) => {
    const setData = metricsEditMode ? setEditData : setFormData;

    setData((prevData) => ({
      ...prevData,
      departments: selectedDepartments || [], // Ensure this is the selected departments array
    }));
  };





  const handleDelete = async (type, id) => {
    try {
      const response = await axios.delete(`${uri}/api/orgsetup/${type}/${id}`);
      const successMessage = response.data.message || "Deletion successful!"; // Default success message
      startToastTimer("success", successMessage); // Show success message in toast
      // console.log("type", type);
      fetchData(); // Refresh data after successful deletion
    } catch (error) {
      // Extract the error message from the server response or use a default message
      const errorMessage = error.response?.data?.error || "Failed to delete data. Please try again.";
      startToastTimer("danger", errorMessage); // Show error message in toast
    }
  };
  
  
  
  

  const handleObjectiveChange = (e) => {
    const selectedObjective = e.target.value;

    // Find the objective data based on the selected objective
    const selectedObjectiveData = objectives.find(
      (obj) => obj.objectiveName === selectedObjective
    );

    // Set the objective and cadence, and auto-populate departments
    setFormData((prevData) => ({
      ...prevData,
      objective: selectedObjective,
      cadence: selectedObjectiveData ? selectedObjectiveData.cadence : '',
      departments: selectedObjectiveData
        ? selectedObjectiveData.departments.map((dept) => ({ label: dept, value: dept })) // Map departments for Select component
        : [],
    }));
  };

  const handleEditInputChange = (e) => {
    const { name, value } = e.target;

    // Update editData state
    setEditData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // If the changed field is 'objective', update the related departments
    if (name === 'objective') {
      const relatedDepartments = objectives.find(
        (obj) => obj.objectiveName === value
      )?.departments || []; // Replace with your logic to fetch departments

      setEditData((prevData) => ({
        ...prevData,
        departments: relatedDepartments.map((dept) => ({
          value: dept,
          label: dept,
        })),
      }));
    }
  };



  const handleEditClick = (type, id) => {
    if (!data[type]) {
      console.error(`Data of type "${type}" not found.`);
      return;
    }

    const itemToEdit = data[type].find(item => item._id === id);

    if (itemToEdit) {
      setEditMode({ [id]: true });
      setEditData({
        ...itemToEdit,
        taskKeyResult: itemToEdit.taskKeyResult || '', // taskKeyResult is already the name
      });
    } else {
      console.error(`Item with id "${id}" not found in ${type}`);
    }
  };



  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let formDataToSend = { ...formData };

    // Use `editData` if in edit mode
    if (metricsEditMode) {
      formDataToSend = { ...editData };
    }

    // Add organization, email, and username to the formData
    const organization = localStorage.getItem("organization");
    const email = localStorage.getItem("email");
    const username = localStorage.getItem("userName");

    formDataToSend.organization = organization;
    formDataToSend.email = email;
    formDataToSend.userName = username;

    // Map departments to save only their value
    formDataToSend.departments = formDataToSend.departments.map((dept) => dept.label);

    // Update measurements to save only the label
    formDataToSend.measurements = formDataToSend.measurements.map((measurement) => measurement.label);

    // Handle `targetDate`: set to `null` if empty, otherwise format it as ISO string
    formDataToSend.targetDate = formDataToSend.targetDate
      ? new Date(formDataToSend.targetDate).toISOString().split("T")[0]
      : null;

    // Modify the formula to include percentage calculation if needed
    if (formDataToSend.metricsUnit === "percentage") {
      formDataToSend.formula = `(${formDataToSend.formula}) * 100`; // Ensure the formula includes *100 for percentage
    }

    try {
      if (metricsEditMode) {
        // Update an existing metric
        await handleSave("metrics", metricsEditMode);
      } else {
        // Create a new metric
        const response = await axios.post(`${uri}/api/orgsetup`, { type: key, formData: formDataToSend });
        startToastTimer("success", "Data saved successfully!");
        await fetchData();
        setFormData(initialFormData); // Reset form data after successful submission
        fetchMetrics();
      }
    } catch (error) {
      const errorMessage = error.response && error.response.data && error.response.data.error
        ? error.response.data.error
        : "Failed to save data. Please try again.";
      startToastTimer("danger", errorMessage);
      console.error(error);
    } finally {
      setLoading(false);
    }
  };



  const fetchMetrics = async () => {
    try {
      const response = await axios.get(`${uri}/api/getorgdetails/metrics/${organization}`);

      const updatedMetrics = response.data.map((metric) => ({
        value: metric.metricsName,
        label: metric.metricsName,
      }));
      setMetricOptions(updatedMetrics);
      setMetrics(response.data);
    } catch (error) {
      console.error("Error fetching metrics:", error);
    }
  };

  const fetchObjectives = async () => {
    try {
      const response = await axios.get(`${uri}/api/getorgdetails/objectives/${organization}`);
      setObjectives(response.data);
    } catch (error) {
      console.error("Error fetching objectives:", error);
    }
  };

  const fetchMeasurement = async () => {
    try {
      const response = await axios.get(`${uri}/api/getorgdetails/measurement/${organization}`);
      setMeasurements(response.data);
    } catch (error) {
      console.error("Error fetching objectives:", error);
    }
  };

  const fetchKeyresults = async () => {
    try {
      const response = await axios.get(`${uri}/api/getorgdetails/keyresults/${organization}`);
      setKeyResults(response.data);
    } catch (error) {
      console.error("Error fetching objectives:", error);
    }
  };

  const fetchClients = async () => {
    try {
      const organization = localStorage.getItem('organization'); // Fetch organization from localStorage
      const response = await axios.get(`${uri}/api/getclients`, {
        headers: {
          'Authorization': `Bearer ${organization}` // Add organization to headers
        }
      });
      setProjectClients(response.data);  // Ensure response.data is set to the correct state
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  const handleClientSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const type = key;
    // Fetch organization from localStorage
    const organization = localStorage.getItem('organization');
    const email = localStorage.getItem('email');
    const userName = localStorage.getItem('userName');

    if (!organization) {
      console.error('No organization found in local storage');
      return;
    }

    // Include the organization in the newClient object
    const clientWithOrganization = {
      ...newClient,
      organization, // Add organization field
      email,
      userName,
    };

    try {
      // Send the client data with the organization field
      await axios.post(`${uri}/api/clients`, clientWithOrganization);
      // Optionally, you can add code to reset the form or update the state here.
      startToastTimer('success', `Data saved successfully for ${type}!`);
      setNewClient({ name: '', clientemail: '', address: '', note: '', currency: '' });
      await fetchData();
    } catch (error) {
      // Check if the error response has a specific error message
      if (error.response && error.response.data && error.response.data.error) {
        const errorMessage = error.response.data.error;

        // Display the error message using toast
        if (errorMessage === 'Client already exists') {
          toastMessage('Client already exists. Please use a different client name.');
        } else {
          startToastTimer(`Error: ${errorMessage}`);
        }
      } else {
        // General error if no specific message is available
        startToastTimer('Error creating new client.');
      }

      console.error('Error creating new client:', error);
    } finally {
      setLoading(false); // Set loading back to false when submission ends
    }
  };




  return (
    <div>
      <div>
        <Header />
        <ToastContainer 
        style={{
          position: "fixed", // Position fixed to the viewport
          bottom: "10px", // Distance from the bottom
          right: "10px", // Distance from the right
          zIndex: 1050, // Ensure it is above other content
        }}
         position="top-end" className="p-3">

          <Toast
            onClose={() => setShowToast(false)}
            show={showToast}
            className="d-inline-block m-1"
            style={{
              minWidth: "300px",
              border: "1px solid rgba(255, 255, 255, 0.2)",
              backdropFilter: "blur(10px)", // For translucency
              WebkitBackdropFilter: "blur(10px)", // Safari support
              borderRadius: "8px",
              overflow: "hidden",
              boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
            }}
          >
            {/* Timer Line */}
            <div
              style={{
                height: "8px", // Increased height
                width: `${progress}%`,
                backgroundColor: toastType === "success" ? "green" : "red",
                transition: "width 30ms linear",
              }}
            />

            {/* Toast Header */}
            <Toast.Header closeButton={false} style={{ background: "rgba(255, 255, 255, 0.1)", borderBottom: "1px solid rgba(255, 255, 255, 0.2)" }}>
              <strong className="me-auto" style={{ color: "#000" }}>
                {toastType === "success" ? "Success" : "Error"}
              </strong>
              <button
                type="button"
                onClick={() => setShowToast(false)}
                style={{
                  background: "none",
                  border: "none",
                  color: "#000",
                  cursor: "pointer",
                  marginLeft: "auto", // Pushes the button to the far right
                  fontSize: "1.2rem", // Larger close icon
                  lineHeight: "1",
                }}
                aria-label="Close"
              >
                &times;
              </button>
            </Toast.Header>

            {/* Toast Body */}
            <Toast.Body style={{ color: "#000" }}>{toastMessage}</Toast.Body>
          </Toast>

        </ToastContainer>
      </div>


      <div style={{ display: "flex", width: "auto", height: "100vh" }}>
        <Sidebar />
        <Container fluid className="tabs-container" style={{ marginLeft: '15vw', marginTop: '10vw' }}>

          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            className="mb-3"
            variant="pills"
          >
            <Tab eventKey="Department" title="Department">
              <div className="tab-content">
                <DepartmentPage />
              </div>

            </Tab>
            <Tab eventKey="Objective" title="Objective">
              <div className="tab-content">
                <h2>Objective</h2>
                <Form onSubmit={handleSubmit} style={{ width: '45vw' }}>
                  <Form.Group controlId="formObjectiveName">
                    <Form.Label>
                      Objective Name <span style={{ color: 'red' }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="objectiveName"
                      value={formData.objectiveName}
                      onChange={handleChange}
                      placeholder="Enter objective name"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="formObjectiveType">
                    <Form.Label>
                      Objective Type <span style={{ color: 'red' }}>*</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="objectiveType"
                      value={formData.objectiveType}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Objective</option>
                      <option value="financial">Financial</option>
                      <option value="people">People</option>
                      <option value="process">Process</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="formObjectiveDescription">
                    <Form.Label>
                      Objective Description <span style={{ color: 'red' }}>*</span>
                    </Form.Label>
                    <Form.Control
                      as="textarea"
                      name="objectiveDescription"
                      value={formData.objectiveDescription}
                      onChange={handleChange}
                      rows={3}
                      placeholder="Enter objective description"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="formCadence">
                    <Form.Label>
                      Cadence <span style={{ color: 'red' }}>*</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="cadence"
                      value={formData.cadence}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Cadence</option>
                      <option value="Monthly">Monthly</option>
                      <option value="quarterly">Quarterly</option>
                      <option value="bi-annual">Bi-annual</option>
                      <option value="annual">Annual</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="formDepartments">
                    <Form.Label>Departments</Form.Label>
                    <Select
                      isMulti
                      name="departments"
                      options={departments} // This should be an array of objects [{ label: 'Dept1', value: 'dept1' }]
                      value={formData.departments}
                      onChange={handleDepartmentChange}
                      placeholder="Select departments"
                    />
                  </Form.Group>

                  <Button variant="primary" type="submit" disabled={loading}>
                    {loading ? "Submitting..." : "Submit"}
                  </Button>
                </Form>


                <Table hover>
                  <thead>
                    <tr>
                      <th>Objective Name</th>
                      <th>Objective Type</th>
                      <th>Objective Description</th>
                      <th>Cadence</th>
                      <th>Department</th> {/* Add Department Header */}
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.objectives.map(obj => (
                      <tr key={obj._id}>
                        <td onDoubleClick={() => handleFieldDoubleClick(obj._id, 'objectiveName', obj.objectiveName)}>
                          {editMode[obj._id] ? (
                            <FormControl
                              name="objectiveName"
                              value={editData.objectiveName || ''}
                              onChange={handleInputChange}
                            />
                          ) : obj.objectiveName}
                        </td>

                        <td onDoubleClick={() => handleFieldDoubleClick(obj._id, 'objectiveType', obj.objectiveType)}>
                          {editMode[obj._id] ? (
                            <FormControl
                              as="select"
                              name="objectiveType"
                              value={editData.objectiveType || ''}
                              onChange={handleInputChange}
                            >
                              <option value="financial">Financial</option>
                              <option value="people">People</option>
                              <option value="process">Process</option>
                            </FormControl>
                          ) : obj.objectiveType}
                        </td>

                        <td onDoubleClick={() => handleFieldDoubleClick(obj._id, 'objectiveDescription', obj.objectiveDescription)}>
                          {editMode[obj._id] ? (
                            <FormControl
                              name="objectiveDescription"
                              value={editData.objectiveDescription || ''}
                              onChange={handleInputChange}
                            />
                          ) : obj.objectiveDescription}
                        </td>

                        <td onDoubleClick={() => handleFieldDoubleClick(obj._id, 'cadence', obj.cadence)}>
                          {editMode[obj._id] ? (
                            <Form.Control
                              as="select"
                              name="cadence"
                              value={editData.cadence || ''}
                              onChange={handleInputChange}
                            >
                              <option value="">Select Cadence</option>
                              <option value="Monthly">Monthly</option>
                              <option value="quarterly">Quarterly</option>
                              <option value="annual">Annual</option>
                              <option value="bi-annual">Bi-annual</option>
                            </Form.Control>
                          ) : obj.cadence}
                        </td>

                        {/* New Department Column */}
                        <td onDoubleClick={() => handleFieldDoubleClick(obj._id, 'departments', obj.departments)}>
                          {editMode[obj._id] ? (
                            <Select
                              isMulti
                              name="departments"
                              options={departments} // [{ label: 'Dept1', value: 'dept1' }]
                              value={editData.departments?.map(dept => ({
                                label: dept,
                                value: departments.find(d => d.label === dept)?.value || dept
                              })) || []}
                              onChange={handleDepartmentChangeEdit}
                              placeholder="Select departments"
                            />

                          ) : obj.departments.join(', ')} {/* Display departments as a comma-separated string */}
                        </td>


                        <td>
                          {editMode[obj._id] ? (
                            <>
                              <Button variant="success" onClick={() => handleSave('Objective', obj._id)}>
                                <BsCheck />
                              </Button>{' '}
                              <Button variant="secondary" onClick={handleCancel}>
                                <BsX />
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button variant="primary" onClick={() => handleEditClick('objectives', obj._id)}>
                                <BsPencil />
                              </Button>{' '}
                              <Button variant="danger" onClick={() => handleDelete('objectives', obj._id)}>
                                <BsTrash />
                              </Button>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>

              </div>
            </Tab>




            <Tab eventKey="KeyResults" title="Key Results">
              <div className="tab-content" style={{ width: "68vw" }}>
                <h2>Key Results</h2>
                <Form onSubmit={handleSubmit} style={{ width: '40vw' }}>
                  <Form.Group controlId="formKeyResultName">
                    <Form.Label>Key Result Name <span style={{ color: 'red' }}>*</span></Form.Label>
                    <Form.Control
                      type="text"
                      name="keyResultName"
                      value={formData.keyResultName}
                      onChange={handleChange}
                      placeholder="Enter key result name"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="formKeyResultDescription">
                    <Form.Label>Key Result Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="keyResultDescription"
                      value={formData.keyResultDescription}
                      onChange={handleChange}
                      rows={3}
                      placeholder="Enter key result description"
                    />
                  </Form.Group>

                  <Form.Group controlId="formFixedDuration">
                    <Form.Check
                      type="checkbox"
                      name="fixedDuration"
                      label="Fixed Duration"
                      checked={formData.fixedDuration}
                      onChange={(e) => {
                        handleChange(e);
                        if (!e.target.checked) {
                          setFormData((prevData) => ({
                            ...prevData,
                            targetDate: null, // set target date to null if unchecked
                          }));
                        }
                      }}
                    />
                  </Form.Group>

                  {formData.fixedDuration && (
                    <Form.Group controlId="formTargetDate">
                      <Form.Label>Target Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="targetDate"
                        value={formData.targetDate}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  )}

                  <Form.Group controlId="formObjective">
                    <Form.Label>Objective</Form.Label>
                    <Form.Control
                      as="select"
                      name="objective"
                      value={formData.objective}
                      onChange={handleObjectiveChange} // Updated to use handleObjectiveChange
                    >
                      <option value="">Select Objective</option>
                      {objectives.map((obj) => (
                        <option key={obj._id} value={obj.objectiveName}>
                          {obj.objectiveName}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  {/* Cadence Dropdown, pre-selected based on objective */}
                  {formData.objective && (
                    <Form.Group controlId="formCadence">
                      <Form.Label>Cadence</Form.Label>
                      <Form.Control
                        as="select"
                        name="cadence"
                        value={formData.cadence}
                        onChange={handleChange}
                      >
                        <option value="">Select Cadence</option>
                        <option value="Monthly">Monthly</option>
                        <option value="quarterly">Quarterly</option>
                        <option value="bi-annual">Bi-annual</option>
                        <option value="annual">Annual</option>
                      </Form.Control>
                    </Form.Group>
                  )}

                  <Form.Group controlId="formMetrics">
                    <Form.Label>Metrics</Form.Label>
                    <Select
                      isMulti
                      name="metrics"
                      options={metrics.map((metric) => ({
                        value: metric.metricsName,
                        label: metric.metricsName,
                      }))}
                      value={formData.metrics.map((metric) => ({
                        value: metric,
                        label: metric,
                      }))}
                      onChange={(selectedOptions) => {
                        const selectedMetrics = selectedOptions.map((option) => option.value);
                        setFormData((prevState) => ({
                          ...prevState,
                          metrics: selectedMetrics,
                        }));
                      }}
                      placeholder="Select Metrics"
                    />
                  </Form.Group>


                  <Form.Group controlId="formDepartments">
                    <Form.Label>Departments</Form.Label>
                    <Select
                      isMulti
                      name="departments"
                      options={departments} // Full list of available departments
                      value={formData.departments} // Currently selected departments
                      onChange={handleDepartmentChange} // Update selected departments
                      placeholder="Select departments"
                      isDisabled={!!formData.objective} // Disable if an objective is selected
                    />
                  </Form.Group>



                  <Button variant="primary" type="submit" disabled={loading}>
                    {loading ? "Submitting..." : "Submit"}
                  </Button>
                </Form>
                <div style={{ overflowX: 'auto', maxWidth: '150%' }}>
                  <Table hover>
                    <thead>
                      <tr>
                        <th>Key Result Name</th>
                        <th>Key Result Description</th>
                        <th>Fixed Duration</th>
                        <th>Target Date</th>
                        <th>Objective</th>
                        <th>Metrics</th>
                        <th>Departments</th>
                        <th>Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.keyResults.map(kr => (
                        <tr key={kr._id}>
                          <td onDoubleClick={() => handleFieldDoubleClick(kr._id, 'keyResultName', kr.keyResultName)}>
                            {editMode[kr._id] ? (
                              <FormControl
                                name="keyResultName"
                                value={editData.keyResultName || ''}
                                onChange={handleInputChange}
                              />
                            ) : kr.keyResultName}
                          </td>

                          <td onDoubleClick={() => handleFieldDoubleClick(kr._id, 'keyResultDescription', kr.keyResultDescription)}>
                            {editMode[kr._id] ? (
                              <FormControl
                                name="keyResultDescription"
                                value={editData.keyResultDescription || ''}
                                onChange={handleInputChange}
                              />
                            ) : kr.keyResultDescription}
                          </td>

                          <td onDoubleClick={() => handleFieldDoubleClick(kr._id, 'fixedDuration', kr.fixedDuration)}>
                            {editMode[kr._id] ? (
                              <Form.Check
                                type="checkbox"
                                name="fixedDuration"
                                label="Fixed Duration"
                                checked={editData.fixedDuration || false}
                                onChange={handleInputChange}
                              />
                            ) : kr.fixedDuration ? 'Yes' : 'No'}
                          </td>

                          <td onDoubleClick={() => handleFieldDoubleClick(kr._id, 'targetDate', kr.targetDate)}>
                            {editMode[kr._id] ? (
                              <FormControl
                                name="targetDate"
                                type="date"
                                value={editData.targetDate || ''}  // Ensure empty string for no value
                                onChange={handleInputChange}
                              />
                            ) : (
                              // Display targetDate in a readable format (e.g., MM/DD/YYYY) or a default message if null
                              kr.targetDate ? new Date(kr.targetDate).toLocaleDateString() : "No Date"
                            )}
                          </td>


                          <td onDoubleClick={() => handleFieldDoubleClick(kr._id, 'objective', kr.objective)}>
                            {editMode[kr._id] ? (
                              <Form.Control
                                as="select"
                                name="objective"
                                value={editData.objective || ''}
                                onChange={handleInputChange}
                              >
                                <option value="">Select Objective</option>
                                {objectives.map((obj) => (
                                  <option key={obj._id} value={obj.objectiveName}>
                                    {obj.objectiveName}
                                  </option>
                                ))}
                              </Form.Control>
                            ) : kr.objective}
                          </td>

                          <td onDoubleClick={() => handleFieldDoubleClick(kr._id, 'metrics', kr.metrics)}>
                            {editMode[kr._id] ? (
                              <Select
                                isMulti
                                name="metrics"
                                options={metrics.map((metric) => ({
                                  value: metric.metricsName,
                                  label: metric.metricsName,
                                }))}
                                value={
                                  Array.isArray(editData.metrics)
                                    ? editData.metrics.map((metric) => ({
                                      value: metric,
                                      label: metric,
                                    }))
                                    : []
                                }
                                onChange={(selectedOptions) => {
                                  const selectedMetrics = selectedOptions.map((option) => option.value);
                                  setEditData((prevState) => ({
                                    ...prevState,
                                    metrics: selectedMetrics,
                                  }));
                                }}
                                placeholder="Select Metrics"
                              />
                            ) : (
                              Array.isArray(kr.metrics) ? kr.metrics.join(', ') : kr.metrics
                            )}
                          </td>

                          <td onDoubleClick={() => handleFieldDoubleClick(kr._id, 'departments', kr.departments)}>
                            {editMode[kr._id] ? (
                              <Select
                                isMulti
                                name="departments"
                                options={departments} // [{ label: 'Dept1', value: 'dept1' }]
                                value={editData.departments?.map(dept => ({
                                  label: dept,
                                  value: departments.find(d => d.label === dept)?.value || dept
                                })) || []}
                                onChange={handleDepartmentChangeEdit}
                                placeholder="Select departments"
                              />

                            ) : kr.departments.join(', ')} {/* Display departments as a comma-separated string */}
                          </td>



                          <td>
                            {editMode[kr._id] ? (
                              <>
                                <Button variant="success" onClick={() => handleSave('KeyResults', kr._id)}>
                                  <BsCheck />
                                </Button>{' '}
                                <Button variant="secondary" onClick={handleCancel}>
                                  <BsX />
                                </Button>
                              </>
                            ) : (
                              <>
                                <Button variant="primary" onClick={() => handleEditClick('keyResults', kr._id)}>
                                  <BsPencil />
                                </Button>{' '}
                                <Button variant="danger" onClick={() => handleDelete('keyResults', kr._id)}>
                                  <BsTrash />
                                </Button>
                              </>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </div>
            </Tab>

            <Tab eventKey="Measurement" title="Measurement">
              <div className="tab-content">
                <h2>Measurement</h2>
                <Form onSubmit={handleSubmit} style={{ width: '45vw' }}>
                  <Form.Group controlId="formMeasurementName">
                    <Form.Label>Measurement Name <span style={{ color: 'red' }}>*</span></Form.Label>
                    <Form.Control
                      type="text"
                      name="measurementName"
                      value={formData.measurementName}
                      onChange={handleChange}
                      placeholder="Enter measurement name"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="formMeasurementDesc">
                    <Form.Label>Measurement Description <span style={{ color: 'red' }}>*</span></Form.Label>
                    <Form.Control
                      as="textarea"
                      name="measurementDesc"
                      value={formData.measurementDesc}
                      onChange={handleChange}
                      rows={3}
                      placeholder="Enter measurement description"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="formMeasurementUnit">
                    <Form.Label>Measurement Unit <span style={{ color: 'red' }}>*</span></Form.Label>
                    <Form.Control
                      as="select"
                      name="measurementUnit"
                      value={formData.measurementUnit}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Unit</option>
                      <option value="Number">Number</option>
                      <option value="String">String</option>
                      <option value="Percentage">Percentage</option>
                      <option value="HH:MM">HH:MM</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="formSource">
                    <Form.Label>Source <span style={{ color: 'red' }}>*</span></Form.Label>
                    <Form.Control
                      as="select"
                      name="source"
                      value={formData.source}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Source</option>
                      <option value="spurtrack">Spurtrack</option>
                      <option value="manual">Manual</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="formFrequency">
                    <Form.Label>Frequency <span style={{ color: 'red' }}>*</span></Form.Label>
                    <Form.Control
                      as="select"
                      name="frequency"
                      value={formData.frequency}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Frequency</option>
                      <option value="daily">Daily</option>
                      <option value="weekly">Weekly</option>
                      <option value="biweekly">Biweekly</option>
                      <option value="monthly">Monthly</option>
                      <option value="quarterly">Quarterly</option>
                      <option value="biannually">Biannually</option>
                      <option value="annually">Annually</option>
                    </Form.Control>
                  </Form.Group>
                  <Form.Group controlId="formDepartments">
                    <Form.Label>Departments</Form.Label>
                    <Select
                      isMulti
                      name="departments"
                      options={departments}
                      value={formData.departments}
                      onChange={handleDepartmentChange}
                      placeholder="Select departments"
                    />
                  </Form.Group>

                  <Button variant="primary" type="submit" disabled={loading}>
                    {loading ? "Submitting..." : "Submit"}
                  </Button>
                  <p></p>
                </Form>

                <Table hover>
                  <thead>
                    <tr>
                      <th>Measurement Name</th>
                      <th>Measurement Description</th>
                      <th>Measurement Unit</th>
                      <th>Source</th>
                      <th>Frequency</th>
                      <th>Departments</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.measurements.map(measurement => (
                      <tr key={measurement._id}>
                        {/* Editing Measurement Name */}
                        <td onDoubleClick={() => handleFieldDoubleClick(measurement._id, 'measurementName', measurement.measurementName)}>
                          {editMode[measurement._id] ? (
                            <FormControl
                              name="measurementName"
                              value={editData.measurementName || ''}
                              onChange={handleInputChange}
                            />
                          ) : measurement.measurementName}
                        </td>

                        {/* Editing Measurement Description */}
                        <td onDoubleClick={() => handleFieldDoubleClick(measurement._id, 'measurementDesc', measurement.measurementDesc)}>
                          {editMode[measurement._id] ? (
                            <FormControl
                              name="measurementDesc"
                              value={editData.measurementDesc || ''}
                              onChange={handleInputChange}
                            />
                          ) : measurement.measurementDesc}
                        </td>

                        {/* Editing Measurement Unit */}
                        <td onDoubleClick={() => handleFieldDoubleClick(measurement._id, 'measurementUnit', measurement.measurementUnit)}>
                          {editMode[measurement._id] ? (
                            <Form.Control
                              as="select"
                              name="measurementUnit"
                              value={editData.measurementUnit || ''}
                              onChange={handleInputChange}
                            >
                              <option value="">Unit</option>
                              <option value="Number">Number</option>
                              <option value="String">String</option>
                              <option value="Percentage">Percentage</option>
                              <option value="HH:MM">HH:MM</option>
                            </Form.Control>
                          ) : measurement.measurementUnit}
                        </td>

                        {/* Editing Source */}
                        <td onDoubleClick={() => handleFieldDoubleClick(measurement._id, 'source', measurement.source)}>
                          {editMode[measurement._id] ? (
                            <Form.Control
                              as="select"
                              name="source"
                              value={editData.source || ''}
                              onChange={handleInputChange}
                            >
                              <option value="">Source</option>
                              <option value="spurtrack">Spurtrack</option>
                              <option value="manual">Manual</option>
                            </Form.Control>
                          ) : measurement.source}
                        </td>

                        {/* Editing Frequency */}
                        <td onDoubleClick={() => handleFieldDoubleClick(measurement._id, 'frequency', measurement.frequency)}>
                          {editMode[measurement._id] ? (
                            <Form.Control
                              as="select"
                              name="frequency"
                              value={editData.frequency || ''}
                              onChange={handleInputChange}
                            >
                              <option value="">Frequency</option>
                              <option value="daily">Daily</option>
                              <option value="weekly">Weekly</option>
                              <option value="biweekly">Biweekly</option>
                              <option value="monthly">Monthly</option>
                              <option value="quarterly">Quarterly</option>
                              <option value="biannually">Biannually</option>
                              <option value="annually">Annually</option>
                            </Form.Control>
                          ) : measurement.frequency}
                        </td>
                        <td onDoubleClick={() => handleFieldDoubleClick(measurement._id, 'departments', measurement.departments)}>
                          {editMode[measurement._id] ? (
                            <Select
                              isMulti
                              name="departments"
                              options={departments} // [{ label: 'Dept1', value: 'dept1' }]
                              value={editData.departments?.map(dept => ({
                                label: dept,
                                value: departments.find(d => d.label === dept)?.value || dept
                              })) || []}
                              onChange={handleDepartmentChangeEdit}
                              placeholder="Select departments"
                            />

                          ) : measurement.departments.join(', ')} {/* Display departments as a comma-separated string */}
                        </td>

                        {/* Action Buttons */}
                        <td>
                          {editMode[measurement._id] ? (
                            <>
                              <Button variant="success" onClick={() => handleSave('Measurement', measurement._id)}>
                                <BsCheck />
                              </Button>{' '}
                              <Button variant="secondary" onClick={handleCancel}>
                                <BsX />
                              </Button>
                            </>
                          ) : (
                            <>
                              <Button variant="primary" onClick={() => handleEditClick('measurements', measurement._id)}>
                                <BsPencil />
                              </Button>{' '}
                              <Button variant="danger" onClick={() => handleDelete('measurements', measurement._id)}>
                                <BsTrash />
                              </Button>
                            </>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Tab>

            <Tab eventKey="Additional" title="Custom Fields">
              <h2>Custom field</h2>
              <div className="tab-content">
                <Form onSubmit={handleSubmit} className="p-3">
                  {/* Additional Field Name */}
                  <Form.Group controlId="formFieldName">
                    <Form.Label>Additional Field Name <span style={{ color: 'red' }}>*</span></Form.Label>
                    <Form.Control
                      type="text"
                      name="additionalFieldName"
                      value={formData.additionalFieldName}
                      onChange={handleChange}
                      placeholder="Enter field name"
                      required
                    />
                  </Form.Group>

                  {/* Description */}
                  <Form.Group controlId="formDescription" className="mt-3">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="description"
                      value={formData.description}
                      onChange={handleChange}
                      placeholder="Enter description"
                      rows={3}
                    />
                  </Form.Group>

                  {/* Required Checkbox */}
                  <Form.Group controlId="formIsRequired" className="mt-3">
                    <Form.Check
                      type="checkbox"
                      name="isRequired"
                      label="Required"
                      checked={formData.isRequired}
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Button variant="primary" type="submit" disabled={loading}>
                    {loading ? "Submitting..." : "Submit"}
                  </Button>
                </Form>

                {/* Table to display additional fields */}
                <Table hover className="mt-5">
                  <thead>
                    <tr>
                      <th>Field Name</th>
                      <th>Description</th>
                      <th>Required</th>
                      <th>Actions</th> {/* New column for Actions */}
                    </tr>
                  </thead>
                  <tbody>
                    {data.additionalfield && data.additionalfield.length > 0 ? (
                      data.additionalfield.map((field) => (
                        <tr key={field._id}>
                          {/* Field Name */}
                          <td>
                            {editMode[field._id] ? (
                              <FormControl
                                type="text"
                                name="additionalFieldName"
                                value={editData.additionalFieldName || ''}
                                onChange={handleInputChange}
                              />
                            ) : (
                              field.additionalFieldName
                            )}
                          </td>

                          {/* Description */}
                          <td>
                            {editMode[field._id] ? (
                              <FormControl
                                as="textarea"
                                name="description"
                                value={editData.description || ''}
                                onChange={handleInputChange}
                                rows={3}
                              />
                            ) : (
                              field.description
                            )}
                          </td>

                          {/* Required */}
                          <td>
                            {editMode[field._id] ? (
                              <Form.Check
                                type="checkbox"
                                name="isRequired"
                                checked={editData.isRequired || false}
                                onChange={(e) => handleInputChange(e, field._id)}
                              />
                            ) : (
                              field.isRequired ? 'Required' : 'Not Required'
                            )}
                          </td>

                          {/* Actions */}
                          <td>
                            {editMode[field._id] ? (
                              <>
                                {/* Save Button */}
                                <Button
                                  variant="success"
                                  onClick={() => handleSave('additionalfield', field._id)}
                                >
                                  Save
                                </Button>
                                {/* Cancel Button */}
                                <Button
                                  variant="secondary"
                                  onClick={() => handleCancel(field._id)}
                                  className="ml-2"
                                >
                                  Cancel
                                </Button>
                              </>
                            ) : (
                              <>
                                {/* Edit Button */}
                                <Button
                                  variant="primary"
                                  onClick={() => handleEditClick('additionalfield', field._id)}
                                >
                                  <BsPencil />
                                </Button>
                                {/* Delete Button */}
                                <Button
                                  variant="danger"
                                  onClick={() => handleDelete('additionalfield', field._id)}
                                  className="ml-2"
                                >
                                  <BsTrash />
                                </Button>
                              </>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center">
                          No additional fields available.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Tab>

            <Tab eventKey="Metrics" title="Metrics">
              <div className="tab-content" style={{ width: "68vw" }}>
                <h2>{metricsEditMode ? "Edit Metric" : "Create Metric"}</h2>
                <Form onSubmit={handleSubmit} >
                  <Form.Group controlId="formMetricsName">
                    <Form.Label>
                      Metrics Name <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="metricsName"
                      value={metricsEditMode ? editData.metricsName : formData.metricsName}
                      onChange={handleChange}
                      placeholder="Enter metrics name"
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="formSource">
                    <Form.Label>
                      Source <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="source"
                      value={metricsEditMode ? editData.source || "" : formData.source || ""}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Source</option>
                      <option value="spurtrack">Spurtrack</option>
                      <option value="manual">Manual</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="formFrequency">
                    <Form.Label>
                      Frequency <span style={{ color: "red" }}>*</span>
                    </Form.Label>
                    <Form.Control
                      as="select"
                      name="frequency"
                      value={metricsEditMode ? editData.frequency || "" : formData.frequency || ""}
                      onChange={handleChange}
                      required
                    >
                      <option value="">Frequency</option>
                      <option value="daily">Daily</option>
                      <option value="weekly">Weekly</option>
                      <option value="biweekly">Biweekly</option>
                      <option value="monthly">Monthly</option>
                      <option value="quarterly">Quarterly</option>
                      <option value="biannually">Biannually</option>
                      <option value="annually">Annually</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="formMetricsUnit">
                    <Form.Label>Metrics Unit</Form.Label>
                    <Form.Control
                      as="select"
                      name="metricsUnit"
                      value={metricsEditMode ? editData.metricsUnit : formData.metricsUnit}
                      onChange={handleChange}
                    >
                      <option value="">Unit</option>
                      <option value="number">Number</option>
                      <option value="percentage">Percentage</option>
                    </Form.Control>
                  </Form.Group>

                  <Form.Group controlId="formFormula">
                    <Form.Label>Formula</Form.Label>
                    <div className="formula-builder">
                      <Select
                        isMulti
                        name="measurements"
                        options={measurements
                          .filter((m) => !formData.measurements.some((selected) => selected.value === m._id)) // Filter out selected measurements
                          .map((m) => ({
                            label: m.measurementName,
                            value: m._id,
                          }))
                        }
                        value={metricsEditMode ? editData.measurements : formData.measurements}
                        onChange={metricsEditMode ? handleMeasurementsChangeEdit : handleMeasurementsChange}
                        placeholder="Select measurements"
                      />

                      <div className="operation-buttons mt-2">
                        <Button onClick={() => handleOperationClick("+")}>+</Button>
                        <Button onClick={() => handleOperationClick("-")}>-</Button>
                        <Button onClick={() => handleOperationClick("*")}>*</Button>
                        <Button onClick={() => handleOperationClick("/")}>/</Button>
                        <Button onClick={() => handleBracketClick("(")}>(</Button>
                        <Button onClick={() => handleBracketClick(")")}>)</Button>
                      </div>

                      <Form.Control
                        as="textarea"
                        rows={3}
                        value={metricsEditMode ? editData.formula : formData.formula}
                        onChange={handleFormulaChange}
                        onKeyDown={handleFormulaKeyDown}
                        placeholder="Your formula will appear here"
                        className="mt-2"
                      />
                    </div>

                    {/* Sample Calculation Preview */}
                    <div className="sample-calculation mt-3">
                      <h5>Sample Calculation:</h5>
                      {sampleCalculation.error ? (
                        <p style={{ color: "red" }}>{sampleCalculation.error}</p>
                      ) : (
                        <p>
                          Formula: <strong>{sampleCalculation.formula}</strong> ={" "}
                          <strong>
                            {sampleCalculation.result}{" "}
                            {formData.metricsUnit === "percentage" ? "%" : ""}
                          </strong>
                        </p>
                      )}
                    </div>
                  </Form.Group>


                  <Form.Group controlId="formDepartments">
                    <Form.Label>Departments</Form.Label>
                    <Select
                      isMulti
                      name="departments"
                      options={departments} // Ensure `departments` is an array of { value, label } objects
                      value={metricsEditMode ? editData.departments : formData.departments} // Ensure selected value matches the structure of `options`
                      onChange={(selectedOptions) => {
                        const selectedDepartments = selectedOptions || []; // Ensure it handles null/undefined
                        if (metricsEditMode) {
                          setEditData((prevState) => ({
                            ...prevState,
                            departments: selectedDepartments,
                          }));
                        } else {
                          setFormData((prevState) => ({
                            ...prevState,
                            departments: selectedDepartments,
                          }));
                        }
                      }}
                      placeholder="Select departments"
                    />
                  </Form.Group>



                  <div className="button-row">
                    <Button variant="primary" type="submit" disabled={loading}>
                      {loading ? "Submitting..." : metricsEditMode ? "Save Changes" : "Submit"}
                    </Button>
                    {metricsEditMode && (
                      <Button
                        variant="secondary"
                        onClick={handleCancel}
                        disabled={loading}
                        className="ml-2"
                      >
                        Cancel
                      </Button>
                    )}
                  </div>
                </Form>

                <Table hover>
                  <thead>
                    <tr>
                      <th>Metric Name</th>
                      <th>Metric Unit</th>
                      <th>Formula</th>
                      <th>Measurements</th>
                      <th>Department</th>
                      <th>Source</th>
                      <th>Frequency</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.metrics.map((metric) => (
                      <tr key={metric._id}>
                        <td>{metric.metricsName}</td>
                        <td>{metric.metricsUnit}</td>
                        <td>{metric.formula}</td>
                        <td>{metric.measurements.join(", ")}</td>
                        <td>{metric.departments.join(", ")}</td>
                        <td>{metric.source}</td>
                        <td>{metric.frequency}</td>
                        <td>
                          <Button variant="primary" onClick={() => handleEdit(metric)}>
                            <BsPencil />
                          </Button>{" "}
                          <Button
                            variant="danger"
                            onClick={() => handleDelete("metrics", metric._id)}
                          >
                            <BsTrash />
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Tab>



            <Tab eventKey="Tasks" title="Tasks" onClick={fetchAdditionalFields}>
              <div className="tab-content" style={{ width: "68vw" }}>
                <h2>Tasks</h2>

                <Form onSubmit={handleSubmit} style={{ width: '40vw' }}>
                  {/* Task Name */}
                  <Form.Group controlId="formTaskName">
                    <Form.Label>Task Name <span style={{ color: 'red' }}>*</span></Form.Label>
                    <Form.Control
                      type="text"
                      name="taskName"
                      value={formData.taskName}
                      onChange={handleChange}
                      placeholder="Enter task name"
                      required
                    />
                  </Form.Group>

                  {/* Task Description */}
                  <Form.Group controlId="formTaskDesc">
                    <Form.Label>Task Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="taskDesc"
                      value={formData.taskDesc}
                      onChange={handleChange}
                      rows={3}
                      placeholder="Enter task description"
                    />
                  </Form.Group>

                  {/* Key Result */}
                  <Form.Group controlId="formTaskKeyResult">
                    <Form.Label>Key Result</Form.Label>
                    <Form.Control
                      as="select"
                      name="taskKeyResult"
                      value={formData.taskKeyResult} // Matches keyResultName
                      onChange={(e) => {
                        const selectedKeyResultId = e.target.value;
                        handleKeyResultChange(selectedKeyResultId);
                      }}
                    >
                      <option value="">None</option>
                      {keyresults.map((keyresult) => (
                        <option key={keyresult._id} value={keyresult.keyResultName}>
                          {keyresult.keyResultName}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>



                  {/* Metrics */}
                  <Form.Group controlId="formTaskMetrics">
                    <Form.Label>Metrics</Form.Label>
                    <Select
                      isMulti
                      name="metrics"
                      options={metricOptions}
                      value={(formData.taskMetrics || []).map((metric) => ({
                        value: metric,
                        label: metric,
                      }))}
                      onChange={(selectedOptions) => {
                        const selectedValues = selectedOptions.map((option) => option.value);

                        // Get measurements for the selected metrics
                        const selectedMeasurements = metrics
                          .filter((metric) => selectedValues.includes(metric.metricsName))
                          .flatMap((metric) => metric.measurements);

                        setFormData({
                          ...formData,
                          taskMetrics: selectedValues,
                          taskMeasurement: [...new Set(selectedMeasurements)], // Update measurements
                        });
                      }}
                      isDisabled={Boolean(formData.taskKeyResult)} // Disable when a key result is selected
                      placeholder="Select metrics"
                    />

                  </Form.Group>





                  <Form.Group controlId="formTaskMeasurement">
                    <Form.Label>Measurement</Form.Label>
                    <Select
                      isMulti
                      name="taskMeasurement"
                      options={measurementOptions}
                      value={formData.taskMeasurement.map((measurement) => ({
                        value: measurement,
                        label: measurement,
                      }))}
                      onChange={(selectedOptions) => {
                        if (selectedOptions.length <= 5) {  // Limit to 5 selections for measurements
                          const selectedValues = selectedOptions.map(option => option.value);
                          setFormData({
                            ...formData,
                            taskMeasurement: selectedValues,
                          });
                        } else {
                          startToastTimer('You can only select up to 5 measurements.');
                        }
                      }}
                    />
                  </Form.Group>


                  {/* Additional Fields */}
                  <Form.Group controlId="formAdditionalFields">
                    <Form.Label>Custom Fields</Form.Label>
                    <Select
                      isMulti
                      name="additionalFields"
                      options={additionalFieldsOptions}
                      value={formData.additionalFields.map((field) => ({
                        value: field,
                        label: additionalFieldsOptions.find(f => f.value === field)?.label || field,
                      }))}
                      onChange={(selectedOptions) => {
                        if (selectedOptions.length <= 6) {  // Limit to 6 selections for additional fields
                          const selectedValues = selectedOptions.map(option => option.value);
                          setFormData({
                            ...formData,
                            additionalFields: selectedValues,
                          });
                        } else {
                          startToastTimer('You can only select up to 6 additional fields.');
                        }
                      }}
                    />
                  </Form.Group>
                  <Form.Group controlId="formDepartments">
                    <Form.Label>Departments</Form.Label>
                    <Select
                      isMulti
                      name="departments"
                      options={departments} // Use the dynamically updated departmentOptions
                      value={formData.departments} // Ensure value is consistent with the selected data
                      onChange={handleDepartmentChange}
                      isDisabled={Boolean(formData.taskKeyResult)} // Disable when a key result is selected
                      placeholder="Select departments"
                    />
                  </Form.Group>


                  <Button variant="primary" type="submit" disabled={loading}>
                    {loading ? "Submitting..." : "Submit"}
                  </Button>
                </Form>
                <br />

                {/* Tasks Table */}
                <Table hover>
                  <thead>
                    <tr>
                      <th>Task Name</th>
                      <th>Task Description</th>
                      <th>Key Result</th>
                      <th>Metrics</th>
                      <th>Measurements</th>
                      <th>Custom Fields</th> {/* New column for Additional Fields */}
                      <th>Department</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.task && data.task.length > 0 ? (
                      data.task.map((task) => (
                        <tr key={task._id}>
                          {/* Task Name */}
                          <td>
                            {editMode[task._id] ? (
                              <FormControl
                                type="text"
                                name="taskName"
                                value={editData.taskName || ''}
                                onChange={handleInputChange}
                                placeholder="Enter task name"
                              />
                            ) : (
                              task.taskName || 'None'
                            )}
                          </td>

                          {/* Task Description */}
                          <td>
                            {editMode[task._id] ? (
                              <FormControl
                                as="textarea"
                                name="taskDesc"
                                value={editData.taskDesc || ''}
                                onChange={handleInputChange}
                                rows={3}
                                placeholder="Enter task description"
                              />
                            ) : (
                              task.taskDesc || 'None'
                            )}
                          </td>
                          {/* Key Result in Edit Mode */}
                          <td>
                            {editMode[task._id] ? (
                              <Form.Control
                                as="select"
                                name="taskKeyResult"
                                value={editData.taskKeyResult || ''} // Use the name, not the ID
                                onChange={(e) => handleEditKeyResultChange(task._id, e.target.value)} // Pass name directly
                              >
                                <option value="">None</option>
                                {keyresults.map((keyresult) => (
                                  <option key={keyresult._id} value={keyresult.keyResultName}> {/* Use the name here */}
                                    {keyresult.keyResultName}
                                  </option>
                                ))}
                              </Form.Control>
                            ) : (
                              task.taskKeyResult || 'None'
                            )}
                          </td>



                          {/* Metrics */}
                          <td>
                            {editMode[task._id] ? (
                              <Select
                                isMulti
                                name="taskMetrics"
                                options={metricOptions} // Use the dynamically updated metricOptions
                                value={(editData.taskMetrics || []).map((metric) => ({
                                  value: metric,
                                  label: metric,
                                }))}
                                onChange={(selectedOptions) => {
                                  const selectedValues = selectedOptions.map((option) => option.value);
                                  setEditData({
                                    ...editData,
                                    taskMetrics: selectedValues,
                                  });
                                }}
                                isDisabled={Boolean(editData.taskKeyResult)} // Disable when key result is selected
                                placeholder="Select metrics"
                              />
                            ) : (
                              task.taskMetrics?.length > 0 ? task.taskMetrics.join(', ') : 'None'
                            )}
                          </td>



                          {/* Measurements */}
                          <td>
                            {editMode[task._id] ? (
                              <Select
                                isMulti
                                name="taskMeasurement"
                                options={measurementOptions}
                                value={editData.taskMeasurement.map((measurement) => ({
                                  value: measurement,
                                  label: measurement,
                                }))}
                                onChange={(selectedOptions) => {
                                  const selectedValues = selectedOptions.map(option => option.value);
                                  setEditData({
                                    ...editData,
                                    taskMeasurement: selectedValues,
                                  });
                                }}
                              />
                            ) : (
                              task.taskMeasurement?.length > 0 ? task.taskMeasurement.join(', ') : 'None'
                            )}
                          </td>

                          {/* Additional Fields */}
                          <td>
                            {editMode[task._id] ? (
                              <Select
                                isMulti
                                name="additionalFields"
                                options={additionalFieldsOptions}
                                value={editData.additionalFields.map((field) => ({
                                  value: field,
                                  label: additionalFieldsOptions.find(f => f.value === field)?.label || field,
                                }))}
                                onChange={(selectedOptions) => {
                                  const selectedValues = selectedOptions.map(option => option.value);
                                  setEditData({
                                    ...editData,
                                    additionalFields: selectedValues,
                                  });
                                }}
                              />
                            ) : (
                              task.additionalFields?.length > 0 ? task.additionalFields.join(', ') : 'None'
                            )}
                          </td>
                          <td onDoubleClick={() => handleFieldDoubleClick(task._id, 'departments', task.departments)}>
                            {editMode[task._id] ? (
                              <Select
                                isMulti
                                name="departments"
                                options={departments} // [{ label: 'Dept1', value: 'dept1' }]
                                value={editData.departments?.map(dept => ({
                                  label: dept,
                                  value: departments.find(d => d.label === dept)?.value || dept
                                })) || []}
                                onChange={handleDepartmentChangeEdit}
                                placeholder="Select departments"
                              />

                            ) : task.departments.join(', ')} {/* Display departments as a comma-separated string */}
                          </td>

                          {/* Actions */}
                          <td>
                            {editMode[task._id] ? (
                              <>
                                <Button
                                  variant="success"
                                  onClick={() => handleSave('task', task._id)}
                                >
                                  Save
                                </Button>
                                <Button
                                  variant="secondary"
                                  onClick={() => handleCancel(task._id)}
                                >
                                  Cancel
                                </Button>
                              </>
                            ) : (
                              <>
                                <Button
                                  variant="primary"
                                  onClick={() => handleEditClick('task', task._id)}
                                >
                                  <BsPencil />
                                </Button>
                                <Button
                                  variant="danger"
                                  onClick={() => handleDelete('task', task._id)}
                                  className="ml-2"
                                >
                                  <BsTrash />
                                </Button>
                              </>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="7" className="text-center">
                          No tasks available.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Tab>

            <Tab eventKey="Clients" title="Clients">
              <Form onSubmit={handleClientSubmit}>
                {/* Name */}
                <Form.Group controlId="formName">
                  <Form.Label>Name <span style={{ color: 'red' }}>*</span></Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={newClient.name}
                    onChange={handleClientChange}
                    required
                  />
                </Form.Group>

                {/* Client Email */}
                <Form.Group controlId="formEmail">
                  <Form.Label>Client Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="clientemail"
                    value={newClient.clientemail}
                    onChange={handleClientChange}
                  />
                </Form.Group>

                {/* Address */}
                <Form.Group controlId="formAddress">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    type="text"
                    name="address"
                    value={newClient.address}
                    onChange={handleClientChange}
                  />
                </Form.Group>

                {/* Note */}
                <Form.Group controlId="formNote">
                  <Form.Label>Note</Form.Label>
                  <Form.Control
                    type="text"
                    name="note"
                    value={newClient.note}
                    onChange={handleClientChange}
                  />
                </Form.Group>

                {/* Currency */}
                <Form.Group controlId="formCurrency">
                  <Form.Label>Currency</Form.Label>
                  <Form.Control
                    as="select"
                    name="currency"
                    value={newClient.currency}
                    onChange={handleClientChange}
                  >
                    <option value="">Currency</option>
                    <option value="INR">INR</option>
                    <option value="USD">USD</option>
                    <option value="EUR">EUR</option>
                  </Form.Control>
                </Form.Group>

                {/* Save Button */}
                <Button variant="primary" type="submit" onClick={handleClientSubmit}>
                  Save
                </Button>
              </Form>

              {/* Clients Table */}
              <Table hover className="mt-4">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Address</th>
                    <th>Note</th>
                    <th>Currency</th>
                    <th>Actions</th> {/* New column for Actions */}
                  </tr>
                </thead>
                <tbody>
                  {data.client && data.client.length > 0 ? (
                    data.client.map((client) => (
                      <tr key={client._id}>
                        {/* Name */}
                        <td>
                          {editMode[client._id] ? (
                            <FormControl
                              type="text"
                              name="name"
                              value={editData.name || ''}
                              onChange={handleInputChange}
                            />
                          ) : (
                            client.name
                          )}
                        </td>

                        {/* Client Email */}
                        <td>
                          {editMode[client._id] ? (
                            <FormControl
                              type="email"
                              name="clientemail"
                              value={editData.clientemail || ''}
                              onChange={handleInputChange}
                            />
                          ) : (
                            client.clientemail
                          )}
                        </td>

                        {/* Address */}
                        <td>
                          {editMode[client._id] ? (
                            <FormControl
                              type="text"
                              name="address"
                              value={editData.address || ''}
                              onChange={handleInputChange}
                            />
                          ) : (
                            client.address
                          )}
                        </td>

                        {/* Note */}
                        <td>
                          {editMode[client._id] ? (
                            <FormControl
                              type="text"
                              name="note"
                              value={editData.note || ''}
                              onChange={handleInputChange}
                            />
                          ) : (
                            client.note
                          )}
                        </td>

                        {/* Currency */}
                        <td>
                          {editMode[client._id] ? (
                            <Form.Control
                              as="select"
                              name="currency"
                              value={editData.currency || ''}
                              onChange={handleInputChange}
                            >
                              <option value="INR">INR</option>
                              <option value="USD">USD</option>
                              <option value="EUR">EUR</option>
                            </Form.Control>
                          ) : (
                            client.currency
                          )}
                        </td>

                        {/* Actions */}
                        <td>
                          {editMode[client._id] ? (
                            <>
                              {/* Save Button */}
                              <Button
                                variant="success"
                                onClick={() => handleSave('client', client._id)}
                              >
                                Save
                              </Button>
                              {/* Cancel Button */}
                              <Button
                                variant="secondary"
                                onClick={() => handleCancel(client._id)}
                                className="ml-2"
                              >
                                Cancel
                              </Button>
                            </>
                          ) : (
                            <>
                              {/* Edit Button */}
                              <Button
                                variant="primary"
                                onClick={() => handleEditClick('client', client._id)}
                              >
                                <BsPencil />
                              </Button>
                              {/* Delete Button */}
                              <Button
                                variant="danger"
                                onClick={() => handleDelete('client', client._id)}
                                className="ml-2"
                              >
                                <BsTrash />
                              </Button>
                            </>
                          )}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="6" className="text-center">
                        No clients available.
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </Tab>
            <Tab eventKey="OKRA" title="OKRA's">
              {/* Only render OkraPage when the OKRA tab is active */}
              {key === "OKRA" && (
                <div className="tab-content">
                  <OkraPage />
                </div>
              )}
            </Tab>

            <Tab eventKey="Projects" title="Project's">
              <div className="tab-content">
                <ProjectTable />
              </div>

            </Tab>
            <Tab eventKey="Team" title="Team">
              <div className="tab-content">
                <Team />
              </div>

            </Tab>


          </Tabs>
        </Container>
      </div>
    </div>
  );
};

export default OrgSetup;