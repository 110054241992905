import React, { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import axios from 'axios';
import './approverwsr.css';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import html2canvas from 'html2canvas';
import { saveAs } from "file-saver"; // To save Word files
import htmlDocx from 'html-docx-js/dist/html-docx'; // For Word export
import uri from '../../config';
import logo from './spurqlabs.png';
import { Dropdown } from 'react-bootstrap';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { Document, Packer, Paragraph, Table, TableRow, TableCell, AlignmentType, WidthType, Header, Footer, ImageRun } from "docx";
const ApproverWSR = ({ approverName }) => {
    const [reportData, setReportData] = useState([]);
    const [selectedReport, setSelectedReport] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedStatus, setSelectedStatus] = useState('');
    const [selectedPreparedBy, setSelectedPreparedBy] = useState('');
    const [message, setMessage] = useState('');
    const [viewStatus, setViewStatus] = useState('');
    const [selectedViewStatus, setSelectedViewStatus] = useState([]);
    const [selectedClient, setSelectedClient] = useState([]);
    const [selectedProject, setSelectedProject] = useState([]);
    const [clientOptions, setClientOptions] = useState([]);
    const [projectOptions, setProjectOptions] = useState([]);
    const [reportIdSearch, setReportIdSearch] = useState('');


    const reportRef = useRef(); // For DOM content to export
    const approvalSectionRef = useRef(); // For hiding the approval section during export

    const statusOptions = [
        { value: 'Approved', label: 'Approve' },
        { value: 'Rejected', label: 'Reject' }
    ];

    const viewStatusOptions = [
        { value: 'Pending', label: 'Pending' },
        { value: 'Approved', label: 'Approved' },
        { value: 'Rejected', label: 'Rejected' }
    ];

    const [preparedByOptions, setPreparedByOptions] = useState([]);

    const fetchReportData = async () => {
        try {
            setLoading(true);
            setError(null);
            const userName = localStorage.getItem('userName');
            const response = await axios.get(`${uri}/api/wsr`, {
                params: { approver: userName, approvedStatus: viewStatus }
            });

            if (response.status === 200) {
                setReportData(response.data);

                // Extract unique clients and projects
                const uniqueClients = Array.from(new Set(response.data.map(report => report.clientName)))
                    .map(client => ({ value: client, label: client }));
                const uniqueProjects = Array.from(new Set(response.data.map(report => report.projectName)))
                    .map(project => ({ value: project, label: project }));

                const uniquePreparedBy = Array.from(new Set(response.data.map(report => report.preparedBy)))
                    .map(preparedBy => ({ value: preparedBy, label: preparedBy }));


                setPreparedByOptions(uniquePreparedBy); // Populate Prepared By options
                setClientOptions(uniqueClients);
                setProjectOptions(uniqueProjects);
            }
        } catch (error) {
            setError(error.response ? { message: "No reports found." } : error);
        } finally {
            setLoading(false);
        }
    };

    const handleApproveReject = async () => { 
        if (!selectedReport) return;
        
        // Ensure selectedPreparedBy is set to the correct value from selectedReport
        const selectedPreparedBy = selectedReport.preparedBy || 'Prepared By'; // Fallback if it's not set
        
        try {
            const updatedPayload = {
                approvedStatus: selectedStatus,
                preparedBy: selectedPreparedBy,  // Send the preparedBy value
                message,
            };
    
            const response = await axios.patch(`${uri}/api/wsr/${encodeURIComponent(selectedReport.reportId)}`, updatedPayload);
    
            if (response.status === 200) {
                toast.success(`Report has been ${selectedStatus.toLowerCase()} successfully.`);
                fetchReportData();
                setSelectedReport(null);
                setSelectedStatus('');
                setMessage('');
            } else {
                console.error('Failed to update report status');
            }
        } catch (error) {
            console.error('Error updating report status:', error);
        }
    };
    
    

    useEffect(() => {
        fetchReportData();
    }, [viewStatus, selectedViewStatus, selectedPreparedBy]);


    const formatDuration = (durationInMinutes) => {
        const hours = Math.floor(durationInMinutes / 60);
        const minutes = durationInMinutes % 60;
        return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
    };

    const formatDate = (isoDate) => new Date(isoDate).toLocaleDateString('en-GB');

    const exportAsPDF = async () => {
        try {
            approvalSectionRef.current.style.display = 'none';
    
            const reportContent = reportRef.current;
            const infoSection = document.querySelector('.info-section');
    
            // Capture the info-section as an image
            const canvas = await html2canvas(infoSection, { scale: 2 });
            const infoSectionImg = canvas.toDataURL('image/png');
            const imgWidth = 11; // Image width in inches (A4 width in landscape)
            const imgHeight = (canvas.height * imgWidth) / canvas.width;
    
            const doc = new jsPDF({
                orientation: 'landscape',
                unit: 'in',
                format: 'a4',
            });
    
            const pageWidth = doc.internal.pageSize.getWidth();
            const pageHeight = doc.internal.pageSize.getHeight();
    
            // Add logo at the top
            const logoWidth = 1.5;
            const logoHeight = 1.0;
            doc.addImage(logo, 'PNG', 0.5, 0.5, logoWidth, logoHeight);
    
            // Add info-section image below the logo
            doc.addImage(infoSectionImg, 'PNG', 0.5, logoHeight + 0.75, imgWidth, imgHeight);
    
            // Prepare table data
            const tableHeaders = [
                ['Task', 'Summary', 'Assignee', 'Duration (HH:MM)', 'Block/Comment', 'Status'],
            ];
    
            const tableData = Object.entries(tasks).flatMap(([taskName, users]) =>
                Object.entries(users).map(([assignee, details]) => [
                    taskName,
                    details.summary || '',
                    details.userName || '',
                    formatDuration(details.duration),
                    details.blockComment || 'N/A',
                    details.status || '',
                ])
            );
    
            // Add table below the info-section image
            doc.autoTable({
                head: tableHeaders,
                body: tableData,
                startY: logoHeight + imgHeight + 1.25, // Place table below the image
                margin: { top: 1, bottom: 1 },
                styles: { fontSize: 10, overflow: 'linebreak' },
            });
    
            // Add footer
            const totalPages = doc.internal.getNumberOfPages();
    
            for (let i = 1; i <= totalPages; i++) {
                doc.setPage(i);
                doc.setFontSize(10);
                doc.text(
                    `Page ${i} of ${totalPages}`,
                    pageWidth - 1,
                    pageHeight - 0.5,
                    { align: 'right' }
                );
                doc.text(
                    "Created with SpurTrack",
                    pageWidth / 2,
                    pageHeight - 0.5,
                    { align: 'center' }
                );
            }
    
            // Save the PDF
            doc.save('WSR_Report.pdf');
    
            approvalSectionRef.current.style.display = 'block';
        } catch (error) {
            console.error('Error exporting PDF:', error);
        }
    };
    
    
    





    const exportAsWord = async () => {
        // Header with Logo and Report Title
        const header = new Header({
            children: [
                new Paragraph({
                    children: [
                        new ImageRun({
                            data: await fetch(logo).then((r) => r.arrayBuffer()), // Fetch logo image
                            transformation: { width: 100, height: 40 }, // Adjust as needed
                        }),
                    ],
                    alignment: AlignmentType.LEFT,
                }),
                new Paragraph({
                    text: "Weekly Status Report",
                    alignment: AlignmentType.CENTER,
                    heading: "Title",
                    spacing: { after: 200 },
                }),
            ],
        });

        // Footer with custom text
        const footer = new Footer({
            children: [
                new Paragraph({
                    text: "Created with SpurTrack",
                    alignment: AlignmentType.CENTER,
                    size: 24,
                }),
            ],
        });

        // Info Section without table, maintaining layout
        const infoSection = [
            new Paragraph({
                text: `Project: ${selectedReport?.projectName || 'ProjectName'}`,
                alignment: AlignmentType.LEFT,
                spacing: { after: 100 },
            }),
            new Paragraph({
                text: `Client: ${selectedReport?.clientName || 'ClientName'}`,
                alignment: AlignmentType.LEFT,
                spacing: { after: 100 },
            }),
            new Paragraph({
                text: `Start Date: ${formatDate(selectedReport?.startDate) || 'StartDate'}`,
                alignment: AlignmentType.LEFT,
                spacing: { after: 100 },
            }),
            new Paragraph({
                text: `End Date: ${formatDate(selectedReport?.endDate) || 'EndDate'}`,
                alignment: AlignmentType.LEFT,
                spacing: { after: 100 },
            }),
            new Paragraph({
                text: `Prepared By: ${selectedReport?.preparedBy || 'Prepared By'}`,
                alignment: AlignmentType.LEFT,
                spacing: { after: 100 },
            }),
            new Paragraph({
                text: `Approved By: ${selectedReport?.approver || 'Approved By'}`,
                alignment: AlignmentType.LEFT,
                spacing: { after: 200 },
            }),
        ];

        // Task Table Headers
        const taskTableHeaders = [
            new TableRow({
                children: [
                    new TableCell({ children: [new Paragraph("Task")], shading: { fill: "808080" } }),
                    new TableCell({ children: [new Paragraph("Summary")], shading: { fill: "808080" } }),
                    new TableCell({ children: [new Paragraph("Assignee")], shading: { fill: "808080" } }),
                    new TableCell({ children: [new Paragraph("Duration (HH:MM)")], shading: { fill: "808080" } }),
                    new TableCell({ children: [new Paragraph("Block/Comment")], shading: { fill: "808080" } }),
                    new TableCell({ children: [new Paragraph("Status")], shading: { fill: "808080" } }),
                ],
            }),
        ];

        // Task Table Rows with Data
        const taskTableRows = Object.entries(tasks).flatMap(([taskName, users]) =>
            Object.entries(users).map(([assignee, details]) => new TableRow({
                children: [
                    new TableCell({ children: [new Paragraph(taskName)] }),
                    new TableCell({ children: [new Paragraph(details.summary || '')] }),
                    new TableCell({ children: [new Paragraph(details.userName || assignee || '')] }),
                    new TableCell({ children: [new Paragraph(formatDuration(details.duration))] }),
                    new TableCell({ children: [new Paragraph(details.blockComment || 'N/A')] }),
                    new TableCell({ children: [new Paragraph(details.status || '')] }),
                ],
            }))
        );

        const taskTable = new Table({
            rows: [...taskTableHeaders, ...taskTableRows],
            width: { size: 100, type: WidthType.PERCENTAGE },
        });

        // Next Week Plan Paragraph
        const nextWeekPlan = new Paragraph({
            text: `Next Week Plan: ${selectedReport.nextWeekPlan || ""}`,
            spacing: { before: 200, after: 200 },
        });

        // Final document with header, info, task table, and footer
        const doc = new Document({
            sections: [
                {
                    headers: { default: header },
                    footers: { default: footer },
                    children: [
                        ...infoSection,  // Adding the info section
                        new Paragraph({ text: " " }), // spacer
                        taskTable, // Task table with data
                        nextWeekPlan, // Next week plan
                    ],
                },
            ],
        });

        // Export document
        Packer.toBlob(doc).then((blob) => {
            saveAs(blob, "Weekly_Status_Report.docx");
        });
    };




    const filteredReports = reportData
        .filter((report) => {
            const matchesStatus = selectedViewStatus.length
                ? selectedViewStatus.includes(report.approvedStatus)
                : true;
            const matchesPreparedBy = selectedPreparedBy.length
                ? selectedPreparedBy.includes(report.preparedBy)
                : true;
            const matchesReportId = reportIdSearch
                ? report.reportId.toLowerCase().includes(reportIdSearch.toLowerCase())
                : true;

            return matchesStatus && matchesPreparedBy && matchesReportId;
        })
        .sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)); // Sort by updatedAt, descending




    if (loading) return <div>Loading...</div>;

    const tasks = selectedReport?.reportData?.airesponse || {};

    const getNoReportsMessage = () => {
        if (!viewStatus) return 'No WSR found for ALL statuses.';
        return `No WSR found for ${viewStatus} status.`;
    };

    const handleStatusChange = (value) => {
        if (value === "All") {
            // If "All" is selected, deselect all other statuses
            setSelectedViewStatus(selectedViewStatus.includes() ? [] : []);
        } else {
            // Remove "All" if any specific status is selected
            let updatedStatus = selectedViewStatus.includes(value)
                ? selectedViewStatus.filter((status) => status !== value) // Deselect
                : [...selectedViewStatus.filter((status) => status !== "All"), value]; // Add status

            setSelectedViewStatus(updatedStatus);
        }
    };

    const handlePreparedByChange = (value) => {
        setSelectedPreparedBy((prev) =>
            prev.includes(value)
                ? prev.filter((item) => item !== value)
                : [...prev, value]
        );
    };

    const handleClientChange = (value) => {
        setSelectedClient(prev =>
            prev.includes(value) ? prev.filter(item => item !== value) : [...prev, value]
        );
    };

    const handleProjectChange = (value) => {
        setSelectedProject(prev =>
            prev.includes(value) ? prev.filter(item => item !== value) : [...prev, value]
        );
    };


    return (
        <div className="container" style={{ boxShadow: "none" }}>
            <ToastContainer />
            <h1 className="text-center my-4">Weekly Status Reports</h1>

            {!selectedReport && (
                <div className="filter-container" style={{ display: 'flex', gap: '1rem', marginBottom: '1rem' }}>
                    <input
                        type="text"
                        placeholder="Search by Report ID"
                        value={reportIdSearch}
                        onChange={(e) => setReportIdSearch(e.target.value)}
                        className="form-control me-2"
                    />
                    <Dropdown autoClose="outside" >
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            Status
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {viewStatusOptions.map(option => (
                                <Dropdown.Item key={option.value} onClick={() => handleStatusChange(option.value)}>
                                    <input
                                        type="checkbox"
                                        checked={selectedViewStatus.includes(option.value)}
                                        onChange={() => handleStatusChange(option.value)}
                                        className="me-2"
                                    />
                                    {option.label}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>

                    <Dropdown autoClose="outside" >
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            Prepared By
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {preparedByOptions.map(option => (
                                <Dropdown.Item key={option.value} onClick={() => handlePreparedByChange(option.value)}>
                                    <input
                                        type="checkbox"
                                        checked={selectedPreparedBy.includes(option.value)}
                                        onChange={() => handlePreparedByChange(option.value)}
                                        className="me-2"
                                    />
                                    {option.label}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                    <Dropdown autoClose="outside">
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            Client
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {clientOptions.map(option => (
                                <Dropdown.Item key={option.value} onClick={() => handleClientChange(option.value)}>
                                    <input
                                        type="checkbox"
                                        checked={selectedClient.includes(option.value)}
                                        onChange={() => handleClientChange(option.value)}
                                        className="me-2"
                                    />
                                    {option.label}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>

                    <Dropdown autoClose="outside">
                        <Dropdown.Toggle variant="success" id="dropdown-basic">
                            Project Name
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {projectOptions.map(option => (
                                <Dropdown.Item key={option.value} onClick={() => handleProjectChange(option.value)}>
                                    <input
                                        type="checkbox"
                                        checked={selectedProject.includes(option.value)}
                                        onChange={() => handleProjectChange(option.value)}
                                        className="me-2"
                                    />
                                    {option.label}
                                </Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>

                </div>
            )}

            {!selectedReport && (
                <>
                    {filteredReports.length === 0 ? (
                        <div className="alert alert-info text-center">
                            {reportIdSearch ? 'No WSR found for the given Report ID.' : (error ? error.message : getNoReportsMessage())}
                        </div>
                    ) : (
                        <table className="table table-bordered table-responsive">
                            <thead>
                                <tr>
                                    <th>Sr No</th>
                                    <th>Timesheet ID</th>
                                    <th>Prepared By</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredReports.map((report, index) => (
                                    <tr
                                        key={report.reportId}
                                        onClick={() => setSelectedReport(report)}
                                        style={{
                                            cursor: 'pointer',
                                            fontWeight: 'bold', // Highlighting reports in bold
                                            backgroundColor: report.approvedStatus === 'Approved' ? '#d4edda' : '' // Optional background color for approved reports
                                        }}
                                    >
                                        <td>{index + 1}</td>
                                        <td>{report.reportId}</td>
                                        <td>{report.preparedBy}</td>
                                        <td>{report.approvedStatus}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}

                </>
            )}


            {selectedReport && (
                <>
                    <div style={{ display: 'flex' }}>
                        <button className="btn btn-secondary mb-3" onClick={() => setSelectedReport(null)}>
                            Back to Reports
                        </button>
                        <div className="export-buttons text-center" style={{ display: 'flex' }}>
                            <button className="btn btn-primary mx-2" onClick={exportAsPDF}>
                                Export as PDF
                            </button>
                            <button className="btn btn-primary mx-2" onClick={exportAsWord}>
                                Export as Word
                            </button>
                        </div>
                    </div>
                    <div ref={reportRef}>
                        <div className="info-section">
                            <div className="row">
                                <div className="col-md-12">
                                    <strong>Report ID: </strong>
                                    <span>{selectedReport?.reportId || 'Report ID'}</span>
                                </div>
                                <div className="col-md-6">
                                    <strong>Project: </strong>
                                    <span>{selectedReport?.projectName || 'ProjectName'}</span>
                                </div>
                                <div className="col-md-6">
                                    <strong>Client: </strong>
                                    <span>{selectedReport?.clientName || 'ClientName'}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <strong>Start Date: </strong>
                                    <span>{formatDate(selectedReport?.startDate) || 'StartDate'}</span>
                                </div>
                                <div className="col-md-6">
                                    <strong>End Date: </strong>
                                    <span>{formatDate(selectedReport?.endDate) || 'EndDate'}</span>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <strong>Prepared By: </strong>
                                    <span>{selectedReport?.preparedBy || 'Prepared By'}</span>
                                </div>
                                <div className="col-md-6">
                                    <strong>Approved By: </strong>
                                    <span>{selectedReport?.approver || 'Approved By'}</span>
                                </div>
                            </div>
                        </div>

                        <table className="table table-bordered table-responsive">
                            <thead>
                                <tr>
                                    <th>Task</th>
                                    <th>Summary</th>
                                    <th>Assignee</th>
                                    <th>Duration (HH:MM)</th>
                                    <th>Block/Comment</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {Object.entries(tasks).map(([taskName, users]) =>
                                    Object.entries(users).map(([assignee, details]) => (
                                        <tr key={`${taskName}-${assignee}`}>
                                            <td>{taskName}</td>
                                            <td>{details.summary || ''}</td>
                                            <td>{details.userName || ''}</td>
                                            <td>{formatDuration(details.duration)}</td>
                                            <td>{details.blockComment || 'N/A'}</td>
                                            <td>{details.status || ''}</td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                        <div>
                            <label>Next Week Plan:</label>
                            <p style={{ whiteSpace: 'pre-line' }}>
                                {selectedReport.nextWeekPlan}
                            </p>
                        </div>

                    </div>

                    <div ref={approvalSectionRef} className="approval-section">
                        <Select
                            options={statusOptions}
                            value={statusOptions.find(option => option.value === selectedStatus)}
                            onChange={(option) => setSelectedStatus(option.value)}
                            placeholder="Approve or Reject"
                            className="my-3"
                        />
                        {selectedStatus === 'Rejected' && (
                            <textarea
                                placeholder="Reason for rejection"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                rows="4"
                                className="form-control"
                            />
                        )}
                        <div className="text-center my-4">
                            <button
                                className="btn btn-success mx-2"
                                disabled={!selectedStatus}
                                onClick={handleApproveReject}
                            >
                                Submit
                            </button>
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default ApproverWSR;