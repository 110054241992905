import React, { useEffect, useState } from "react";
import axios from "axios";
import uri from "../../config";

const OkraPage = () => {
  const [departments, setDepartments] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");
  const [okraData, setOkraData] = useState({
    objectives: [],
    metrics: [],
    keyResults: [],
    tasks: [],
    measurements: [],
  });

  // Fetch departments and dropdown data on load
  useEffect(() => {
    fetchDepartments();
  }, []);

  const fetchDepartments = async () => {
    try {
      const organization = localStorage.getItem("organization");
      const response = await axios.get(`${uri}/api/departments`, {
        params: { organization },
      });
      setDepartments(response.data);
    } catch (error) {
      console.error("Error fetching departments:", error);
    }
  };

  const fetchOkraData = async (departmentName) => {
    try {
      const organization = localStorage.getItem("organization");
      const response = await axios.get(`${uri}/api/okra`, {
        params: { organization, department: departmentName },
      });
      setOkraData(response.data);
    } catch (error) {
      console.error("Error fetching OKRA data:", error);
    }
  };

  const handleDepartmentChange = (e) => {
    const departmentName = e.target.value;
    setSelectedDepartment(departmentName);
    fetchOkraData(departmentName);
  };

  return (
    <div className="container">
      <h2>OKRA's</h2>
      <div className="form-group">
        <label htmlFor="departmentSelect">Select Department</label>
        <select
          id="departmentSelect"
          className="form-control"
          value={selectedDepartment}
          onChange={handleDepartmentChange}
        >
          <option value="">Select a department</option>
          {departments.map((dept) => (
            <option key={dept._id} value={dept.name}>
              {dept.name}
            </option>
          ))}
        </select>
      </div>

      {selectedDepartment && (
        <div className="mt-4">
          <table className="table table-bordered">
            <thead>
              <tr>
                <th>Objectives</th>
                <th>Metrics</th>
                <th>Key Results</th>
                <th>Tasks</th>
                <th>Measurements</th>
              </tr>
            </thead>
            <tbody>
              {okraData.objectives.length > 0 ||
                okraData.metrics.length > 0 ||
                okraData.keyResults.length > 0 ||
                okraData.tasks.length > 0 ? (
                <tr>
                  {/* Objectives */}
                  <td>
                    {okraData.objectives
                      .map((objective) => objective.objectiveName)
                      .join(", ") || "No data"}
                  </td>

                  {/* Metrics */}
                  <td>
                    {okraData.metrics
                      .map((metric) => metric.metricsName)
                      .join(", ") || "No data"}
                  </td>

                  {/* Key Results */}
                  <td>
                    {okraData.keyResults
                      .map((kr) => kr.keyResultName)
                      .join(", ") || "No data"}
                  </td>

                  {/* Tasks */}
                  <td>
                    {okraData.tasks
                      .map((task) => task.taskName)
                      .join(", ") || "No data"}
                  </td>

                  {/* Measurements */}
                  <td>
                    {okraData.tasks
                      .flatMap((task) => task.taskMeasurement) // Extract measurements from each task
                      .join(", ") || "No data"}
                  </td>
                </tr>
              ) : (
                <tr>
                  <td colSpan={5} className="text-center">
                    No data available
                  </td>
                </tr>
              )}
            </tbody>


          </table>
        </div>
      )}
    </div>
  );
};

export default OkraPage;
