import React, { useEffect, useState } from 'react';
import { Table, Button, Modal, Dropdown } from 'react-bootstrap';
import axios from 'axios';
import uri from '../../config';
import { Bar, Pie, Line, Doughnut } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
} from 'chart.js';

ChartJS.register(ArcElement, Tooltip, Legend, BarElement, CategoryScale, LinearScale, PointElement, LineElement);

const MetricDashboard = ({
  selectedProject = [],
  selectedUsers = [],
  selectedTasks = [],
  startDate = null,
  endDate = null,
  selectedClient = [],
  selectedMetrics = [],
}) => {
  const [metricsResults, setMetricsResults] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedFormula, setSelectedFormula] = useState('');
  const [chartType, setChartType] = useState('Bar'); // Default chart type

  useEffect(() => {
    const filtersAreEmpty =
      !(selectedProject?.length) &&
      !(selectedUsers?.length) &&
      !(selectedTasks?.length) &&
      !startDate &&
      !endDate &&
      !(selectedClient?.length) &&
      !(selectedMetrics?.length);

    if (filtersAreEmpty) {
      axios.get(`${uri}/api/metricscalc/metrics`)
        .then((response) => {
          setMetricsResults(response.data.metricsResults);
        })
        .catch((error) => {
          console.error('Error fetching all metrics:', error);
        });
      return;
    }

    const filters = {
      project: selectedProject || [],
      team: selectedUsers || [],
      task: selectedTasks || [],
      startDate: startDate || null,
      endDate: endDate || null,
      client: selectedClient || [],
      metrics: selectedMetrics || [],
    };

    const filteredParams = Object.keys(filters).reduce((acc, key) => {
      if (filters[key] && (Array.isArray(filters[key]) ? filters[key].length > 0 : filters[key] !== null)) {
        acc[key] = filters[key];
      }
      return acc;
    }, {});

    const queryParams = new URLSearchParams(filteredParams).toString();

    axios.get(`${uri}/api/metricscalc/metrics?${queryParams}`)
      .then((response) => {
        setMetricsResults(response.data.metricsResults);
      })
      .catch((error) => {
        console.error('Error fetching filtered metrics:', error);
      });

  }, [selectedProject, selectedUsers, selectedTasks, startDate, endDate, selectedClient, selectedMetrics]);

  const handleInfoClick = (formula) => {
    setSelectedFormula(formula || "NA");
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedFormula('');
  };

  const filteredMetricsResults = metricsResults.filter(metric =>
    !selectedMetrics || selectedMetrics.length === 0 || selectedMetrics?.includes(metric.metricName)
  );

  const backgroundColors = [
    '#007bff', '#ffc107', '#28a745', '#dc3545', '#17a2b8', '#6c757d', '#6610f2', '#e83e8c', '#fd7e14', '#20c997'
  ];

  const chartData = {
    labels: filteredMetricsResults.map(metric => metric.metricName),
    datasets: [
      {
        label: 'Metric Values',
        data: filteredMetricsResults.map(metric => metric.metricValue || 0),
        backgroundColor: backgroundColors,
        borderWidth: 1,
      },
    ],
  };

  const chartOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const index = tooltipItem.dataIndex;
            const metric = filteredMetricsResults[index];
            return [
              `Measurements: ${metric.measurements?.map(m => `${m.measurementName}: ${m.value}`).join(', ') || 'NA'}`,
              `Result: ${metric.metricValue || 'NA'}`,
              `User: ${metric.userNames?.join(', ') || 'NA'}`
            ];
          }
        }
      }
    }
  };

  const renderChart = () => {
    switch (chartType) {
      case 'Bar':
        return <Bar data={chartData} options={chartOptions} />;
      case 'Pie':
        return <Pie data={chartData} options={chartOptions} />;
      case 'Line':
        return <Line data={chartData} options={chartOptions} />;
      case 'Doughnut':
        return <Doughnut data={chartData} options={chartOptions} />;
      default:
        return null;
    }
  };

  return (
    <div className="container mt-5">
      <div className="d-flex justify-content-between align-items-center">
        <h2>Metrics Table</h2>
        <Dropdown>
          <Dropdown.Toggle variant="primary" id="dropdown-basic">
            Select Chart
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item onClick={() => setChartType('Bar')}>Bar Chart</Dropdown.Item>
            <Dropdown.Item onClick={() => setChartType('Pie')}>Pie Chart</Dropdown.Item>
            <Dropdown.Item onClick={() => setChartType('Line')}>Line Chart</Dropdown.Item>
            <Dropdown.Item onClick={() => setChartType('Doughnut')}>Doughnut Chart</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <div className="chart-container my-4" style={{ height: '400px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        {renderChart()}
      </div>

      <Table bordered hover>
        <thead>
          <tr>
            <th>Metrics Name</th>
            <th>Measurements</th>
            <th>Result / Info</th>
            <th>User</th>
          </tr>
        </thead>
        <tbody>
          {filteredMetricsResults.length > 0 ? (
            filteredMetricsResults.map((metric, index) => (
              <tr key={index}>
                <td>{metric.metricName}</td>
                <td>
                  {metric.measurements ? (
                    metric.measurements.map((measurement, idx) => (
                      <div key={idx}>
                        <strong>{measurement.measurementName}:</strong> {measurement.value}
                      </div>
                    ))
                  ) : (
                    'No measurements available'
                  )}
                </td>
                <td style={{ textAlign: "center" }}>
                  <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div>
                      {metric.metricValue
                        ? metric.metricsUnit === "percentage"
                          ? `${(metric.metricValue).toFixed(2)}%`
                          : (metric.metricValue).toFixed(2)
                        : "NA"}
                    </div>
                    <span
                      style={{
                        fontSize: "1.5rem",
                        cursor: "pointer",
                        color: "#007bff",
                        marginLeft: "8px",
                      }}
                      onClick={() => handleInfoClick(metric.formula)}
                    >
                      ⓘ
                    </span>
                  </div>
                </td>
                <td>
                  {metric.userNames && metric.userNames.length > 0
                    ? metric.userNames.join(', ')
                    : 'No users available'}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan="4" className="text-center">No Metrics Available</td>
            </tr>
          )}
        </tbody>
      </Table>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Formula Used</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <strong>{selectedFormula}</strong>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MetricDashboard;
