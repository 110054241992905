import React, { useState, useEffect } from "react";
import axios from "axios";
import uri from '../../config';
import Header from "../Header/index";
import Sidebar from "../sidebar";
import { Dropdown, Modal, Button, Form } from 'react-bootstrap';
import { Toast, ToastContainer } from 'react-bootstrap';

const ProjectTable = () => {
  const [projects, setProjects] = useState([]);
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [users, setUsers] = useState([]);
  const [clients, setClients] = useState([]);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [projectclients, setProjectClients] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [editedProject, setEditedProject] = useState({});
  const [showToast, setShowToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastVariant, setToastVariant] = useState(""); // Success by default
  const [progress, setProgress] = useState(0);
  const [filters, setFilters] = useState({
    active: "",
    client: "",
    access: "",
    billable: "",
    search: "",
    user: ""
  });
  const role = localStorage.getItem("role");
  const [showClientModal, setShowClientModal] = useState(false);
  const [newProject, setNewProject] = useState({
    projectName: "",
    clientName: "",
    engagementType: "",
    currencyType: "",
    regionType: "",
    billable: false
  });

  const handleModalClose = () => {
    setShowModal(false);
    setNewProject({
      projectName: "",
      clientName: "",
      engagementType: "",
      currencyType: "",
      regionType: "",
      billable: false
    });
  }
  const handleModalShow = () => {
    setShowModal(true);
    fetchClients();
  }
  const handleClientModalShow = () => setShowClientModal(true);   // Function to show the modal
  const handleClientModalClose = () => setShowClientModal(false);

  const handleProjectInputChange = (e) => {
    const { name, value } = e.target;
    setNewProject({ ...newProject, [name]: value });
  };


  useEffect(() => {


    fetchClients();  // Fetch clients when the component mounts
  }, []);
  const fetchClients = async () => {
    try {
      const organization = localStorage.getItem('organization'); // Fetch organization from localStorage
      const response = await axios.get(`${uri}/api/getclients`, {
        headers: {
          'Authorization': `Bearer ${organization}` // Add organization to headers
        }
      });
      setProjectClients(response.data);
       
    } catch (error) {
      console.error('Error fetching clients:', error);
    }
  };

  useEffect(() => {
    const fetchProjectsAndUsers = async () => {
      try {
        const organizationId = localStorage.getItem("organization");

        // Fetch Projects
        const response = await axios.get(`${uri}/api/projects`, {
          params: { organizationId }
        });
        const projectData = response.data.data;
        setProjects(projectData);
        setFilteredProjects(projectData);

        // Extract unique clients from the projects

        

        // Fetch Users for "Access" dropdown
        const userResponse = await axios.get(`${uri}/api/getusers`, {
          params: { organization: organizationId }
        });
        setUsers(userResponse.data || []);

        setLoading(false);
      } catch (error) {
        setError("Failed to fetch data");
        setLoading(false);
      }
    };

    fetchProjectsAndUsers();
  }, []);

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters({ ...filters, [name]: value });
  };


  const clearFilters = () => {
    setFilters({
      active: "",
      client: "",
      access: "",
      billable: "",
      search: "",
      user: ""
    });
  };

  useEffect(() => {
    let updatedProjects = projects;

    // Filter by user
    if (filters.user) {
      const selectedUser = users.find(user => user._id === filters.user);
      if (selectedUser && selectedUser.selectedProjects.length > 0) {
        updatedProjects = projects.filter(project =>
          selectedUser.selectedProjects.includes(project._id)
        );
      }
    }

    // Filter by active status
    if (filters.active !== "") {
      updatedProjects = updatedProjects.filter(project => project.active === (filters.active === "true"));
    }

    // Filter by client
    if (filters.client) {
      updatedProjects = updatedProjects.filter(project => project.clientName === filters.client);
    }

    // Filter by access type
    if (filters.access) {
      updatedProjects = updatedProjects.filter(project => project.accessType === filters.access);
    }

    // Filter by billable status
    if (filters.billable !== "") {
      updatedProjects = updatedProjects.filter(project => project.billable === (filters.billable === "true"));
    }

    // Search by project name or client name
    if (filters.search) {
      updatedProjects = updatedProjects.filter(project =>
        project.projectName.toLowerCase().includes(filters.search.toLowerCase()) ||
        project.clientName.toLowerCase().includes(filters.search.toLowerCase())
      );
    }

    setFilteredProjects(updatedProjects);
  }, [filters, projects, users]);

  const handleEditClick = async (project) => {
    // Fetch latest clients
    await fetchClients();  // Assuming fetchClients is an async function that updates projectclients state
  
    // Update the state for the edited project
    setEditedProject({ ...project });
  
    // Open the modal
    setEditModalOpen(true);
  };

  const handleEditSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(`${uri}/api/editprojects/${editedProject._id}`, { ...editedProject, clientName: editedProject.clientname });
      setEditModalOpen(false);
      setProjects((prevProjects) => prevProjects.map((project) => project._id === editedProject._id ? editedProject : project));
    } catch (error) {
      console.error('Error updating project:', error);
    }
  };

  const handleSubmit = async () => {
    try {
      const organization = localStorage.getItem("organization");
      const email = localStorage.getItem("email");

      const response = await axios.post(`${uri}/api/projects`, {
        organization,
        email,
        ...newProject
      });

      if (response.status === 201) {
        setProjects([...projects, response.data.data]);
        setFilteredProjects([...projects, response.data.data]);
        setShowModal(false);

        setToastMessage("Project created successfully!");
        startToastTimer("success");
      }

      setNewProject({
        projectName: "",
        clientName: "",
        engagementType: "",
        currencyType: "",
        regionType: "",
        billable: false
      });
    } catch (error) {
      console.error("Error creating project", error);
      setToastMessage("Failed to create project. Please try again.");
      startToastTimer("danger");
    }
  };

  const startToastTimer = (variant) => {
    setProgress(0); // Reset progress
    setToastVariant(variant);
    setShowToast(true);

    const duration = 3000; // Total duration in milliseconds
    const interval = 30; // Update every 30ms
    let elapsed = 0;

    const timer = setInterval(() => {
      elapsed += interval;
      setProgress((elapsed / duration) * 100); // Calculate percentage progress
      if (elapsed >= duration) {
        clearInterval(timer);
        setShowToast(false); // Close toast
      }
    }, interval);
  };




  if (loading) return <p>Loading projects...</p>;
  if (error) return <p>{error}</p>;

  // Function to handle archiving/unarchiving a project
  const handleArchiveToggle = async (projectId, currentStatus) => {
    try {
      const response = await axios.put(`${uri}/api/projects/${projectId}`, {
        done: !currentStatus // Toggle the archive status
      });

      if (response.status === 200) {
        // Update the project list with the updated project status
        const updatedProjects = projects.map((project) =>
          project._id === projectId ? { ...project, done: !currentStatus } : project
        );
        setProjects(updatedProjects);
        setFilteredProjects(updatedProjects);
      }
    } catch (error) {
      console.error("Error archiving/unarchiving project", error);
    }
  };

  // Function to handle activating/deactivating a project
  const handleActiveToggle = async (projectId, currentStatus) => {
    try {
      const response = await axios.put(`${uri}/api/projects/${projectId}`, {
        active: !currentStatus // Toggle the active status
      });

      if (response.status === 200) {
        // Update the project list with the updated project status
        const updatedProjects = projects.map((project) =>
          project._id === projectId ? { ...project, active: !currentStatus } : project
        );
        setProjects(updatedProjects);
        setFilteredProjects(updatedProjects);
      }
    } catch (error) {
      console.error("Error activating/deactivating project", error);
    }
  };

  const handleProjectNameClick = (projectName) => {
    localStorage.setItem('selectedProjectName', projectName);  // Save project name to localStorage
    window.location.href = '/projectdetails'  // Redirect to the project details page
  };

  return (
    <div>
      <ToastContainer position="top-end" className="p-3">
        <Toast
          onClose={() => setShowToast(false)}
          show={showToast}
          className="toast-with-timer"
        >
          {/* Timer Line */}
          <div
            style={{
              height: "4px",
              width: `${progress}%`,
              backgroundColor: toastVariant === "success" ? "green" : "red",
              transition: "width 30ms linear",
            }}
          />
          <Toast.Header className="d-flex align-items-center">
            {/* Close Button */}

            {/* Toast Header Title */}
            <strong className="me-auto">{toastMessage.includes("success") ? "Success" : "Error"}</strong>
          </Toast.Header>
          {/* Toast Body */}
          <Toast.Body>{toastMessage}</Toast.Body>
        </Toast>
      </ToastContainer>



      <div className="container" style={{ width: "80vw" }}>
        <h2>Project List</h2>

        {/* Filters */}
        <div className="filters" style={{ display: "flex", marginBottom: "20px", flexWrap: "wrap" }}>
          <div className="form-group" style={{ width: "10vw" }}>
            <label htmlFor="active" style={{ marginBottom: "5px" }}>Status</label> {/* Placeholder Title for Status */}
            <select
              name="active"
              id="active"
              onChange={handleFilterChange}
              value={filters.active}
              className="form-control"
            >
              <option value="">All</option> {/* Default option */}
              <option value="true">Active</option>
              <option value="false">Inactive</option>
            </select>
          </div>

          <div className="form-group" style={{ width: "10vw", marginLeft: "2vw" }}>
            <label htmlFor="client" style={{ marginBottom: "5px" }}>Clients</label> {/* Placeholder Title for Clients */}
            <select
              name="client"
              id="client"
              onChange={handleFilterChange}
              value={filters.client}
              className="form-control"
            >
              <option value="">All</option> {/* Default option */}
              {clients.length > 0 ? (
                clients.map(client => (
                  <option key={client} value={client}>{client}</option>
                ))
              ) : (
                <option disabled>No clients available</option>
              )}
            </select>
          </div>

          <div className="form-group" style={{ width: "10vw", marginLeft: "2vw" }}>
            <label htmlFor="billable" style={{ marginBottom: "5px" }}>Billable</label> {/* Placeholder Title for Billable */}
            <select
              name="billable"
              id="billable"
              onChange={handleFilterChange}
              value={filters.billable}
              className="form-control"
            >
              <option value="">All</option> {/* Default option */}
              <option value="true">Yes</option>
              <option value="false">No</option>
            </select>
          </div>

          <div className="form-group" style={{ width: "20vw", marginLeft: "2vw", display: "flex", alignItems: "center", marginTop: '1.7rem' }}>
            <button
              style={{
                backgroundColor: '#dbdeef',
                border: 'none',
                padding: '8px 16px',
                marginRight: '5px',
                fontSize: "1rem"   // Set the width of the "Clear Filters" button
              }}
              onClick={clearFilters}
            >
              Clear Filters
            </button>
            {role === "SuperAdmin" && (
              <button
                title="New Project"
                style={{
                  backgroundColor: 'white',
                  color: 'black',
                  border: 'none',
                  width: '35px',        // Set the width and height to the same value for a circular button
                  height: '35px',
                  borderRadius: '50%',   // Makes the button circular
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  fontSize: '2rem',      // Adjust font size for the "+" symbol
                }}
                onClick={handleModalShow}
              >
                +
              </button>
            )}
          </div>
        </div>

        <div className="form-group" style={{ marginBottom: "20px", width: '57vw' }}>
          <input
            type="text"
            name="search"
            value={filters.search}
            onChange={handleFilterChange}
            className="form-control"
            placeholder="Search by project or client name"
          />
        </div>



        {/* Project Table */}
        <table className="table" style={{ width: '57vw' }}>
          <thead>
            <tr>
              <th>Project Name</th>
              <th>Client</th>
              <th>Engagement Type</th>
              <th>Currency</th>
              <th>Region</th>
              <th>Billable</th>
              {role === 'SuperAdmin' && <th>Actions</th>} {/* Render Actions column header only for SuperAdmin */}
            </tr>
          </thead>
          <tbody>
            {filteredProjects.length > 0 ? (
              filteredProjects.map((project) => (
                <tr key={project._id} style={{ textDecoration: project.done ? 'line-through' : 'none' }}>
                  {/* Conditionally render clickable Project Name for SuperAdmin only */}
                  {role === 'SuperAdmin' ? (
                    <td
                      style={{ cursor: 'pointer', color: 'blue', textDecoration: 'underline' }}
                      onClick={() => handleProjectNameClick(project.projectName)}
                    >
                      {project.projectName}
                    </td>
                  ) : (
                    <td>{project.projectName}</td>  // Plain text for non-SuperAdmin roles
                  )}
                  <td>{project.clientName}</td>
                  <td>{project.engagementType}</td>
                  <td>{project.currencyType}</td>
                  <td>{project.regionType}</td>
                  <td>{project.billable ? "Yes" : "No"}</td>
                  {role === 'SuperAdmin' && (  /* Conditionally render Actions cell */
                    <td>
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Button className="btn btn-primary" style={{ marginRight: '5px' }} onClick={() => handleEditClick(project)}>
                          Edit
                        </Button>
                        <Dropdown>
                          <Dropdown.Toggle variant="success" id="dropdown-basic">
                            ⋮
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {/* Archive/Unarchive */}
                            <Dropdown.Item onClick={() => handleArchiveToggle(project._id, project.done)}>
                              {project.done ? 'Unarchive' : 'Archive'}
                            </Dropdown.Item>
                            {/* Activate/Deactivate */}
                            <Dropdown.Item onClick={() => handleActiveToggle(project._id, project.active)}>
                              {project.active ? 'Deactivate' : 'Activate'}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={role === 'SuperAdmin' ? 7 : 6}>No projects found.</td> {/* Adjust colspan based on role */}
              </tr>
            )}
          </tbody>
        </table>

        {/* Modal for Creating a New Project */}
        <Modal show={showModal} onHide={handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Create New Project</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group controlId="formProjectName">
                <Form.Label>Project Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter project name"
                  name="projectName"
                  value={newProject.projectName}
                  onChange={handleProjectInputChange}
                />
              </Form.Group>

              {/* Dropdown for Client Name populated by fetched data */}
              <Form.Group controlId="formClientName">
                <Form.Label>Client Name</Form.Label>
                <Form.Control
                  as="select"
                  name="clientName"
                  value={newProject.clientName} // This should be the state value for selected client
                  onChange={(e) => {
                    const selectedClientName = e.target.value;
                    const selectedClient = projectclients.find(client => client.name === selectedClientName);

                    setNewProject({
                      ...newProject,
                      clientName: selectedClientName,
                      currencyType: selectedClient ? selectedClient.currency : '' // Automatically set currency
                    });
                  }}
                >
                  <option value="">Select Client</option>
                  {projectclients.map((client) => (
                    <option key={client._id} value={client.name}>
                      {client.name}
                    </option>
                  ))}
                </Form.Control>
              </Form.Group>


              {/* Dropdown for Engagement Type */}
              <Form.Group controlId="formEngagementType">
                <Form.Label>Engagement Type</Form.Label>
                <Form.Control
                  as="select"
                  name="engagementType"
                  value={newProject.engagementType}
                  onChange={handleProjectInputChange}
                >
                  <option value="">Select Engagement Type</option>
                  <option value="Fixed Price">Fixed Price</option>
                  <option value="Fixed Monthly">Fixed Monthly</option>
                  <option value="T&M - Hourly">T&M - Hourly</option>
                  <option value="T&M - Daily">T&M - Daily</option>
                </Form.Control>
              </Form.Group>

              {/* Dropdown for Currency Type */}
              <Form.Group controlId="formCurrencyType">
                <Form.Label>Currency Type</Form.Label>
                <Form.Control
                  as="select"
                  name="currencyType"
                  value={newProject.currencyType} // This is automatically updated
                  onChange={handleProjectInputChange}
                >
                  <option value="">Select Currency Type</option>
                  <option value="INR">INR</option>
                  <option value="USD">USD</option>
                  <option value="EUR">EUR</option>
                </Form.Control>
              </Form.Group>


              {/* Dropdown for Region Type */}
              <Form.Group controlId="formRegionType">
                <Form.Label>Region Type</Form.Label>
                <Form.Control
                  as="select"
                  name="regionType"
                  value={newProject.regionType}
                  onChange={handleProjectInputChange}
                >
                  <option value="">Select Region Type</option>
                  <option value="International">International</option>
                  <option value="Domestics">Domestics</option>
                </Form.Control>
              </Form.Group>

              <Form.Group controlId="formBillable">
                <Form.Label>Billable</Form.Label>
                <Form.Check
                  type="checkbox"
                  label="Is Billable"
                  name="billable"
                  checked={newProject.billable}
                  onChange={(e) =>
                    setNewProject({ ...newProject, billable: e.target.checked })
                  }
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleSubmit}>
              Create Project
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal
          show={editModalOpen} // Change this to `show` instead of `isOpen`
          onHide={() => setEditModalOpen(false)} // Use `onHide` for the close event
          style={{
            overlay: {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              backgroundColor: "rgba(0,0,0,0.8)"
            },
            content: {
              width: "600px",
              height: "570px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "10px",
              margin: '0', // Reset any default margin
              padding: '20px', // Add padding if needed
              inset: 'auto' // Center the modal within the overlay
            }
          }}
        >
          <form onSubmit={handleEditSubmit}>
            <div className="modal-header">
              <h5 className="modal-title">Edit Project</h5>
            </div>

            <div className="modal-body">
              {/* Project Name */}
              <div className="mb-3">
                <label htmlFor="project-name" className="form-label">Enter project name</label>
                <input
                  type="text"
                  className="form-control"
                  id="project-name"
                  name="projectName"
                  value={editedProject.projectName}
                  onChange={(e) => setEditedProject({ ...editedProject, projectName: e.target.value })}
                />
              </div>

              {/* Client Name */}
              <div className="mb-3">
                <label htmlFor="client" className="form-label">Select Client</label>
                <select
                  id="client"
                  name="clientName"
                  className="form-select"
                  value={editedProject.clientName} // Should match the clientName from the project
                  onChange={(e) =>setEditedProject({ ...editedProject, clientName: e.target.value })}
        
                  
                >
                  <option value="">Select Client</option>
                  {projectclients.map((client, index) => (
                    <option key={index} value={client.name}>
                      {client.name} {/* Render the name of the client here */}
                    </option>
                  ))}
                </select>

              </div>

              {/* Engagement Type */}
              <div className="mb-3">
                <label htmlFor="template" className="form-label">Engagement Type</label>
                <select
                  id="template"
                  name="engagementType"
                  className="form-select"
                  value={editedProject.engagementType}
                  onChange={(e) => setEditedProject({ ...editedProject, engagementType: e.target.value })}
                >
                  <option value="">Select Engagement Type</option>
                  <option value="Fixed Price">Fixed Price</option>
                  <option value="Fixed Monthly">Fixed Monthly</option>
                  <option value="T&M - Hourly">T&M - Hourly</option>
                  <option value="T&M - Daily">T&M - Daily</option>
                </select>
              </div>

              {/* Currency */}
              <div className="mb-3">
                <label htmlFor="currency" className="form-label">Currency</label>
                <select
                  id="currency"
                  name="currencyType"
                  className="form-select"
                  value={editedProject.currencyType}
                  onChange={(e) => setEditedProject({ ...editedProject, currencyType: e.target.value })}
                >
                  <option value="">Select currency</option>
                  <option value="INR">INR</option>
                  <option value="USD">USD</option>
                </select>
              </div>

              {/* Billing Type */}
              <div className="mb-3">
                <label htmlFor="billable" className="form-label">Billing</label>
                <select
                  id="billable"
                  name="billable"
                  className="form-select"
                  value={editedProject.billable ? "Billable" : "Non-Billable"}
                  onChange={(e) => setEditedProject({ ...editedProject, billable: e.target.value === "Billable" })}
                >
                  <option value="Billable">Billable</option>
                  <option value="Non-Billable">Non-Billable</option>
                </select>
              </div>

              {/* Region Type */}
              <div className="mb-3">
                <label htmlFor="select-regionType" className="form-label">Select Region Type</label>
                <select
                  id="select-regionType"
                  name="regionType"
                  className="form-select"
                  value={editedProject.regionType}
                  onChange={(e) => setEditedProject({ ...editedProject, regionType: e.target.value })}
                >
                  <option value="">Select Region Type</option>
                  <option value="Domestics">Domestics</option>
                  <option value="International">International</option>
                </select>
              </div>
            </div>

            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={() => setEditModalOpen(false)}>Cancel</button>
              <button type="submit" className="btn btn-primary">Save Changes</button>
            </div>
          </form>
        </Modal>
      </div>
    </div>
  );
};

export default ProjectTable;