import React, { useState, useEffect } from 'react';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import { Modal, Button, Form } from 'react-bootstrap';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import './CalendarComponent.css';
import axios from 'axios';
import uri from '../../config'; // Assuming this imports your API URI
import Sidebar from "../sidebar";
import Header from "../Header/index";
import { useNavigate } from 'react-router-dom'; // Import useNavigate from react-router-dom
import Select from 'react-select';  // Import react-select

const localizer = momentLocalizer(moment);

const CalendarComponent = () => {
  const [events, setEvents] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState('');
  const [approverNames, setApproverNames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userName, setUserName] = useState(localStorage.getItem('userName') || '');
  const [isSidebarExpanded, setIsSidebarExpanded] = useState(true);
  const [userRole, setUserRole] = useState(localStorage.getItem('role') || ''); // Retrieve role from localStorage
  const [selectedApprover, setSelectedApprover] = useState(null); // State to handle selected approver
  const navigate = useNavigate(); // Initialize useNavigate

  useEffect(() => {
    const storedDate = localStorage.getItem('selectedDate');
    if (storedDate) {
      setSelectedDate(formatDate(storedDate));
    }
    fetchEvents();
  }, []);

  const formatDate = (date) => {
    return moment(date).format('MMM DD YYYY');
  };

  const CustomToolbar = ({ label, onNavigate }) => (
    <div className="custom-toolbar">
      <div className="toolbar-buttons">
        <Button onClick={() => onNavigate('PREV')}>Previous Month</Button>
        <Button onClick={() => onNavigate('TODAY')}>Today</Button>
        <Button onClick={() => onNavigate('NEXT')}>Next Month</Button>

      </div>
      <div className="month-label">
        <span>{label}</span> {/* Display the current month */}
      </div>
    </div>
  );


  const isLockedDay = (date) => {
    const today = moment().startOf('day');
    const yesterday = moment().subtract(1, 'days').startOf('day');
    const dayOfWeek = today.day(); // 0 (Sunday) to 6 (Saturday)
  
    // If the date falls on a weekend (Saturday or Sunday), lock it
    if (moment(date).day() === 0 || moment(date).day() === 6) {
      return true; // Locked
    }
  
    // Unlock only today and yesterday
    if (moment(date).isSame(today, 'day') || moment(date).isSame(yesterday, 'day')) {
      return false; // Unlocked
    }
  
    // If today is Monday, unlock last Friday as well
    if (dayOfWeek === 1) { // Monday
      const lastFriday = moment().subtract(3, 'days').startOf('day'); // Go back to Friday
      if (moment(date).isSame(lastFriday, 'day')) {
        return false; // Unlock Friday
      }
    }
  
    return true; // Lock all other dates
  };
  
  




  const handleSelectSlot = ({ start, end }) => {
    const formattedDate = formatDate(start);

    // If the selected date is in the future, don't allow selection
    if (moment(start).isAfter(moment(), 'day')) {
      return;  // Prevent selecting future dates
    }

    setSelectedEvent({ start, end });
    setSelectedDate(formattedDate);
    localStorage.setItem('selectedDate', formattedDate);

    if (userRole === 'SuperAdmin') {
      navigate('/timesheet');
      return;
    }

    if (!isLockedDay(start) || isApprovedForUser(start)) {
      navigate('/timesheet');
    } else {
      setIsModalOpen(true);
    }
  };


  const handleSelectEvent = (event) => {
    const formattedDate = formatDate(event.start);
    setSelectedEvent(event);
    setSelectedDate(formattedDate);
    localStorage.setItem('selectedDate', formattedDate);

    if (userRole === 'SuperAdmin') {
      navigate('/timesheet');
      return;
    }

    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedEvent(null);
  };

  const saveEvent = async (event) => {
    try {
      const response = await axios.post(`${uri}/api/accessrequest`, event, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 201) {
        const savedEvent = response.data;
        setEvents([...events, savedEvent]); // Add the new event to the list
        closeModal();
        fetchEvents();  // Refetch events to reflect updated approval status
      } else {
        console.error('Failed to save event');
      }
    } catch (error) {
      console.error('Error saving event:', error);
    }
  };


  const isApprovedForUser = (date) => {
    return events.some(event =>
      event.username === userName &&
      event.approvalStatus === 'Approved' &&
      moment(date).isSame(moment(event.start), 'day')  // Ensure correct comparison of dates
    );
  };

  const dayPropGetter = (date) => {
    // If the date is approved for the user, do not apply the locked-day class
    if (isApprovedForUser(date)) {
      return {};  // No special styling for approved days
    }

    // Otherwise, apply locked-day class if it's a locked day
    if (isLockedDay(date)) {
      return { className: 'locked-day' };
    }

    return {};  // No special class if it's not a locked day
  };

  const eventPropGetter = (event) => {
    let className = 'event';
    if (event.approvalStatus === 'Approved') {
      className += ' approved';
    } else if (event.approvalStatus === 'Rejected') {
      className += ' rejected';
    }

    return {
      className,
      children: (
        <div className="event-content">
          <div>{event.title}</div>
          <div>{moment(event.start).format('MMM DD YYYY')}</div>
        </div>
      )
    };
  };

  const fetchEvents = async () => {
    try {
      const username = localStorage.getItem('userName');
      const response = await axios.get(`${uri}/api/accessrequest?username=${username}`);
      if (response.status === 200) {
        setEvents(response.data);  // Ensure that approvalStatus is part of the data
      } else {
        console.error('Failed to fetch events');
      }
    } catch (error) {
      console.error('Error fetching events:', error);
    }
  };


  const fetchApproverNames = async () => {
    try {
      const organization = localStorage.getItem('organization');  // Get the organization from localStorage
      const userRole = localStorage.getItem('role'); // Get the user's role from localStorage

      if (!organization || !userRole) return;

      setLoading(true);

      // Adjust the API call based on the user's role
      const apiParams = { organization };
      if (userRole === 'Member') {
        apiParams.role = ['Admin', 'SuperAdmin']; // Fetch both Admin and SuperAdmin if role is "Member"
      } else if (userRole === 'Admin') {
        apiParams.role = ['SuperAdmin']; // Fetch only SuperAdmin if role is "Admin"
      }

      const response = await axios.get(`${uri}/api/approvername`, { params: apiParams });

      if (response.status === 200) {
        setApproverNames(response.data);
      } else {
        console.error('Failed to fetch approver names');
      }
    } catch (error) {
      console.error('Error fetching approver names:', error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchApproverNames();
  }, []);

  const options = approverNames.map(user => ({
    value: `${user.firstName} ${user.lastName}`,
    label: `${user.firstName} ${user.lastName}`,
  }));

  const handleChange = (selectedOption) => {
    setSelectedApprover(selectedOption.value);  // Store selected approver's name
  };

  return (
    <div>
      <Header />
      <div style={{ display: "flex", width: "100%", height: "100vh" }}>
        <Sidebar isExpanded={isSidebarExpanded} setIsExpanded={setIsSidebarExpanded} />
        <div className="calendar-container" style={{ marginTop: "7vw" }}>
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            style={{ height: '100%', width: '75vw' }}
            selectable
            onSelectSlot={handleSelectSlot}
            onSelectEvent={handleSelectEvent}
            views={['month']}
            dayPropGetter={dayPropGetter}
            eventPropGetter={eventPropGetter}
            components={{ toolbar: CustomToolbar }}
          />


          {isModalOpen && userRole !== 'SuperAdmin' && (
            <Modal show={isModalOpen} onHide={closeModal} dialogClassName="modal-lg">
              <Modal.Header closeButton>
                <Modal.Title>Request Access</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form onSubmit={(e) => {
                  e.preventDefault();
                  const description = e.target.elements.description.value;
                  const username = e.target.elements.username.value;
                  const approverName = selectedApprover;  // Use selected approver from state
                  const start = selectedEvent.start;
                  const end = selectedEvent.end;
                  const selectedDateToSend = moment(selectedDate, 'MMM DD YYYY').toISOString();

                  if (description && username && approverName) {
                    saveEvent({
                      description,
                      username,
                      approverName,
                      start,
                      end,
                      selectedDate: selectedDateToSend,
                      projectName: localStorage.getItem('selectedProjectName'),
                    });
                  }
                }}>
                  <Form.Group controlId="formTitle">
                    <Form.Label>Selected Date: </Form.Label>
                    <span>{selectedDate}</span>
                  </Form.Group>

                  <Form.Group controlId="formUsername">
                    <Form.Label>User Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="username"
                      placeholder="Enter username"
                      defaultValue={userName}
                      readOnly
                      required
                    />
                  </Form.Group>

                  <Form.Group controlId="formDescription">
                    <Form.Label>Request Description</Form.Label>
                    <Form.Control as="textarea" name="description" rows={3} placeholder="Enter description" required />
                  </Form.Group>

                  <Form.Group controlId="formApproverName">
                    <Form.Label>Approver Name</Form.Label>
                    {loading ? (
                      <Select
                        options={[]}
                        isLoading={true}
                        placeholder="Loading..."
                      />
                    ) : (
                      <Select
                        options={options}
                        onChange={handleChange}
                        isSearchable
                        placeholder="Select Approver"
                        name="approverName"
                        required
                      />
                    )}
                  </Form.Group>


                  <Button variant="primary" type="submit">Request Access</Button>
                </Form>
              </Modal.Body>
            </Modal>
          )}
        </div>
      </div>
    </div>
  );
};

export default CalendarComponent;